import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'auth-button-loading-in-form',
  templateUrl: './button-loading-in-form.component.html',
})
export class ButtonLoadingInFormComponent {
  @Input() type?: string = "button";
  @Input() class?: string = "btn btn-info";
  @Input() iconClass?: string = "fa fa-file-text-o";
  @Input() isDisabled?: boolean;
  @Input() isLoading?: boolean;
  @Input() label: string = "entity.action.loadLines";

  @Output() buttonClick = new EventEmitter<void>();

  handleClick(event: Event) {
    if (this.isDisabled || this.isLoading) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    this.buttonClick.emit();
  }
}

