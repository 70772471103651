export enum ToplistReportType {
  BUYER = 'BUYER',
  BUYER_PRODUCT = 'BUYER_PRODUCT',
  BUYER_PRODUCT_GROUP = 'BUYER_PRODUCT_GROUP',
  PRODUCT = 'PRODUCT',
  PRODUCT_GROUP = 'PRODUCT_GROUP',
  PRODUCTS_COMPARATIVE = 'PRODUCTS_COMPARATIVE',
  DELIVERY_ADDRESS = 'DELIVERY_ADDRESS',
  ADDRESS_PRODUCT = 'ADDRESS_PRODUCT'
}
