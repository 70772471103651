import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SessionStorageService } from 'ngx-webstorage';

import { VERSION } from 'app/app.constants';
import { LANGUAGES } from 'app/config/language.constants';
import { AccountService } from 'app/core/auth/account.service';
import { LoginService } from 'app/login/login.service';

import { PrimeNGConfig } from 'primeng/api';
import { MenuItem } from 'primeng/api';
import { HomeService } from 'app/home/home.service';
import { BookingType } from 'app/entities/enumerations/booking-type.model';
import { BankTransferOrderCategory } from 'app/entities/enumerations/bank-transfer-order-category.model';
import { GlobalsService } from 'app/shared/globals/globals.service';
import { DataSharingService } from 'app/shared/services/data-sharing.service';
import { StockMovementTypeCategory } from 'app/entities/enumerations/stock-movement-type-category.model';
import { AssetEventType } from 'app/entities/enumerations/asset-event-type.model';
import { BalanceStatementType } from 'app/entities/enumerations/balannce-statement-type.model';

import { GlStatementGenerateComponent } from 'app/entities/gl-statement/open-close/gl-statement-generate/gl-statement-generate.component';
import { GlStatementLoadOpeningComponent } from 'app/entities/gl-statement/open-close/gl-statement-load-opening/gl-statement-load-opening.component';
import { GlStatementCloseYearComponent } from 'app/entities/gl-statement/open-close/gl-statement-close-year/gl-statement-close-year.component';
import { Observable, Subscription, of, tap } from 'rxjs';
import { LoginComponent } from 'app/login/login.component';
import { IntrastatCloseMonthComponent } from 'app/entities/intrastat-report/intrastat-close-month/intrastat-close-month.component';
import { ToplistReportType } from 'app/entities/enumerations/toplist-report-type';
import { AccountsBalanceArrangeComponent } from 'app/entities/invoice-in/accounts-balance-arrange/accounts-balance-arrange.component';

@Component({
  selector: 'auth-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('dialogContainer', { read: ViewContainerRef }) dialogContainer!: ViewContainerRef;
  inProduction?: boolean;
  languages = LANGUAGES;
  openAPIEnabled?: boolean;
  version = '';
  items: MenuItem[] = [];
  subs: Subscription[] = [];
  activeItem?: string;
  previousItem?: string;

  constructor(
    private loginService: LoginService,
    private translateService: TranslateService,
    private sessionStorage: SessionStorageService,
    private accountService: AccountService,
    private homeService: HomeService,
    private globalsService: GlobalsService,
    private dataSharingService: DataSharingService,
    private config: PrimeNGConfig,
    private router: Router,
  ) {

    if (VERSION) {
      this.version = VERSION.toLowerCase().startsWith('v') ? VERSION : 'v' + VERSION;
    }

    this.subs.push(this.homeService.getLoggedInName.subscribe(() => this.setMenuItems()));
  
    this.subs.push(this.dataSharingService.activeMenuItem.subscribe( value => {
      if (value) {
        this.goToMenuItem(value, true);
      }
    }));
}

  ngOnInit(): void {   
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    // this.router.onSameUrlNavigation = 'reload';

    // this.subs.push(this.profileService.getProfileInfo().subscribe(profileInfo => {
    //   this.inProduction = profileInfo.inProduction;
    //   this.openAPIEnabled = profileInfo.openAPIEnabled;
      this.setMenuItems();
    // }));
  }

  ngAfterViewInit(): void {
// console.log('AfterViewInit'); 
    this.selectMenuItem();
  }

  ngOnDestroy(): void {
// console.log(`OnDestroy (activeId: ${this.activeItem})`);      
    this.subs.forEach(sub => sub.unsubscribe());
    this.dataSharingService.activeMenuItem.next(this.activeItem ?? '');
  }

  private fixupItems(items: MenuItem[]): void {
    items?.forEach((item) => {
      const firstLetterOfId = item.id?.substring(0,1);
      if (firstLetterOfId !== 'C' && firstLetterOfId !== "M") {
        item.command = (e) => {this.goToMenuItem(e.item?.id ?? '')};
      }
      this.fixupItems(item.items!);
    });
  }

  selectMenuItem(): void {
// console.log(`selectMenuItem (previousId: ${this.previousItem}, activeId: ${this.activeItem})`);      
    if (this.previousItem && this.activeItem) {
      const pe = document.getElementById(this.previousItem) ? document.getElementById(this.previousItem) : document.getElementById(this.previousItem+'_header');
      if (pe) {
        pe.getElementsByClassName("p-menuitem-text").item(0)?.classList.remove("active-menu-item");
        pe.getElementsByClassName("p-menuitem-icon").item(0)?.classList.remove("active-menu-item");
      }
// console.log(pe);      
    }

    if (this.activeItem) {
      this.previousItem = this.activeItem;
      const ae = document.getElementById(this.previousItem) ? document.getElementById(this.previousItem) : document.getElementById(this.previousItem+'_header');
      if (ae) {
        ae.getElementsByClassName("p-menuitem-text").item(0)?.classList.add("active-menu-item");
        ae.getElementsByClassName("p-menuitem-icon").item(0)?.classList.add("active-menu-item");
      }
// console.log(ae);      
    }
  }

  setMenuItems(): Observable<void> {
// console.log('setMenuItems');      
    this.items = [
      {
        id: "S0",
        label: this.translateService.instant('menu.home'),
        icon: 'pi pi-fw pi-home',
        routerLinkActiveOptions: { exact: true },
        routerLink: ['/'],
      },
      {
        id: "S1",
        label: this.translateService.instant('menu.dashboard'),
        icon: 'pi pi-fw pi-chart-line',
        routerLinkActiveOptions: { exact: true },
        routerLink: ['/dashboard'],
        visible: this.isAuthenticated() && this.isApplicationSelected() && (this.hasFullRights() || this.hasAccess('MENU_DASHBOARD')),
      },
      // {
      //   label: this.translateService.instant('menu.dashboard'),
      //   icon: 'pi pi-fw pi-chart-line',
      //   routerLinkActiveOptions: { exact: true },
      //   routerLink: ['/task-view'],
      //   visible: this.isAuthenticated() && this.isApplicationSelected() && (this.hasFullRights() || this.hasAccess('FELADATOK')),
      // },
      {
        id: "M2",
        label: this.translateService.instant('menu.trade.main'),
        visible: this.isAuthenticated() && this.isApplicationSelected() && this.isCommerce() && (this.hasFullRights() || this.hasAccess('MENU_KERESKEDELEM', false)),
        items: [
          { 
            id: "S2_0",
            label: this.translateService.instant('menu.invoices.contract'),
            disabled: !this.hasFullRights() && !this.hasAccess('SZERZODES'),
            routerLink: ['/contract'],
            visible: true,
          },
          { separator: true },
          {
            id: "S2_1",
            label: this.translateService.instant('menu.trade.purchaseOrder'),
            disabled: !this.hasFullRights() && !this.hasAccess('BESZREND'),
            routerLink: ['/order', 'B'],
            routerLinkActiveOptions: { exact: true },
          },
          { separator: true },
          {
            id: "S2_2",
            label: this.translateService.instant('menu.trade.sellOrder'),
            disabled: !this.hasFullRights() && !this.hasAccess('VEVOIREND'),
            routerLink: ['/order', 'V'],
            routerLinkActiveOptions: { exact: true },
          },
          {
            id: "S2_3",
            label: this.translateService.instant('menu.trade.routeSettlement'),
            disabled: !this.hasFullRights() && !this.hasAccess('TURAELSZ'),
            routerLink: ['/reports-filter/route-settlement-rf'],
            routerLinkActiveOptions: { exact: true },
            visible: this.hasAccess('TURAELSZ', false)
          },
          { separator: true },
          {
            id: "S2_4",
            label: this.translateService.instant('menu.stock.outgoingInvoice'),
            routerLink: ['/invoice-out'],
            queryParams: {'invoiceType': 'KE'},
            disabled: !this.hasFullRights() && !this.hasAccess('ARUSZLAK'),
            routerLinkActiveOptions: { exact: true },
            visible: this.isCommerce()
          },
          {
            id: "S2_5",
            label: this.translateService.instant('menu.stock.stockOutInvoice'),
            routerLink: ['/invoice-out'],
            queryParams: {'invoiceType': 'KK'},
            disabled: !this.hasFullRights() && !this.hasAccess('SZLAK'),
            routerLinkActiveOptions: { exact: true },
            visible: this.isCommerce()
          },
          { 
            id: "S2_6",
            label: this.translateService.instant('menu.trade.genAggregateVoices'),
            disabled: !this.hasFullRights() && !this.hasAccess('GYSZLAGEN'),
            routerLink: ['/reports-filter/gen-aggregate-voices'],
            routerLinkActiveOptions: { exact: true },
          },
          { 
            id: "S2_7",
            label: this.translateService.instant('menu.trade.EPRView'),
            disabled: !this.hasFullRights() && !this.hasAccess('EPR'),
            routerLink: ['/epr-view'],
            routerLinkActiveOptions: { exact: true },
          },
      { visible: this.isIntrastat(), separator: true },
          {
            id: "M2_0",
            label: this.translateService.instant('menu.stock.intrastat'),
            disabled: !this.hasFullRights() && !this.hasAccess('MENU_INTRASTAT'),
            visible: this.isIntrastat(),
            items: [
              { 
                id: "S2_0_1",
                label: this.translateService.instant('menu.stock.intrastatlist') ,
                disabled: !this.hasFullRights() && !this.hasAccess('INTRASTATLIS'),
                routerLink: ['/reports-filter/intrastat-list-rf'],
                routerLinkActiveOptions: { exact: true },
              },
              { 
                id: "S2_0_2",
                label: this.translateService.instant('menu.stock.intrastatfile') ,
                disabled: !this.hasFullRights() && !this.hasAccess('INTRASTATFAJL'),
                routerLink: ['/intrastat-report'],
                routerLinkActiveOptions: { exact: true },
              },
              { 
                id: "C2_0_3",
                label: this.translateService.instant('menu.stock.intrastatclose') ,
                disabled: !this.hasFullRights() && !this.hasAccess('INTRASTATZARAS'),
                command: () => this.loadIntrastatCloseMonthDialog(),
              },
            ],
          },
          // { separator: true  },
          {
            id: "M2_1",
            label: this.translateService.instant('menu.trade.lists'),
            disabled: !this.hasFullRights() && !this.hasAccess('MENU_KERLIST'),
            items: [
              { 
                id: "S2_1_1",
                label: this.translateService.instant('menu.trade.toplist.buyer') ,
                disabled: !this.hasFullRights() && !this.hasAccess('RANGLISTAK'),
                routerLink: ['/reports-filter/toplist-rf', ToplistReportType.BUYER],
                routerLinkActiveOptions: { exact: true },
              },
              { 
                id: "S2_1_2",
                label: this.translateService.instant('menu.trade.toplist.product') ,
                disabled: !this.hasFullRights() && !this.hasAccess('RANGLISTAK'),
                routerLink: ['/reports-filter/toplist-rf', ToplistReportType.PRODUCT],
                routerLinkActiveOptions: { exact: true },
              },
              { 
                id: "S2_1_3",
                label: this.translateService.instant('menu.trade.toplist.productGroup') ,
                disabled: !this.hasFullRights() && !this.hasAccess('RANGLISTAK'),
                routerLink: ['/reports-filter/toplist-rf', ToplistReportType.PRODUCT_GROUP],
                routerLinkActiveOptions: { exact: true },
              },
              { 
                id: "S2_1_4",
                label: this.translateService.instant('menu.trade.toplist.buyerProduct') ,
                disabled: !this.hasFullRights() && !this.hasAccess('RANGLISTAK'),
                routerLink: ['/reports-filter/toplist-rf', ToplistReportType.BUYER_PRODUCT],
                routerLinkActiveOptions: { exact: true },
              },
              { 
                id: "S2_1_5",
                label: this.translateService.instant('menu.trade.toplist.buyerProductGroup') ,
                disabled: !this.hasFullRights() && !this.hasAccess('RANGLISTAK'),
                routerLink: ['/reports-filter/toplist-rf', ToplistReportType.BUYER_PRODUCT_GROUP],
                routerLinkActiveOptions: { exact: true },
              },
              { 
                id: "S2_1_6",
                label: this.translateService.instant('menu.trade.toplist.buyerAddress') ,
                disabled: !this.hasFullRights() && !this.hasAccess('RANGLISTAK'),
                routerLink: ['/reports-filter/toplist-rf', ToplistReportType.DELIVERY_ADDRESS],
                routerLinkActiveOptions: { exact: true },
              },
              { 
                id: "S2_1_7",
                label: this.translateService.instant('menu.trade.toplist.buyerAddressProduct') ,
                disabled: !this.hasFullRights() && !this.hasAccess('RANGLISTAK'),
                routerLink: ['/reports-filter/toplist-rf', ToplistReportType.ADDRESS_PRODUCT],
                routerLinkActiveOptions: { exact: true },
              },
            ],
          },
        ],
      },     
      {
        id: "M3",
        label: this.translateService.instant('menu.stock.main'),
        visible: this.isAuthenticated() && this.isApplicationSelected() && this.isCommerce() && (this.hasFullRights() || this.hasAccess('MENU_KESZLET', false)),
        items: [
          {
            id: "S3_0",
            label: this.translateService.instant('menu.stock.stockMovementIn'),
            disabled: !this.hasFullRights() && !this.hasAccess('KESZBESZBEV'),
            routerLink: ['/stock-movement', StockMovementTypeCategory.BESZERZES],
            routerLinkActiveOptions: { exact: true },
          },
          {
            id: "S3_1",
            label: this.translateService.instant('menu.stock.stockMovementOtherIn'),
            disabled: !this.hasFullRights() && !this.hasAccess('KESZEGYBEV'),
            routerLink: ['/stock-movement', StockMovementTypeCategory.EGYEB_BE],
            routerLinkActiveOptions: { exact: true },
          },
          { separator: true },
          {
            id: "S3_2",
            label: this.translateService.instant('menu.stock.stockMovementOut'),
            disabled: !this.hasFullRights() && !this.hasAccess('KESZLERT'),
            routerLink: ['/stock-movement', StockMovementTypeCategory.ERTEKESITES],
            routerLinkActiveOptions: { exact: true },
          },
          {
            id: "S3_3",
            label: this.translateService.instant('menu.stock.stockMovementOtherOut'),
            disabled: !this.hasFullRights() && !this.hasAccess('KESZEGYKIAD'),
            routerLink: ['/stock-movement', StockMovementTypeCategory.EGYEB_KI],
            routerLinkActiveOptions: { exact: true },
          },
          {
            id: "S3_4",
            label: this.translateService.instant('menu.stock.stockTransfer'),
            disabled: !this.hasFullRights() && !this.hasAccess('KESZATTAROL'),
            routerLink: ['/stock-transfer'],
            routerLinkActiveOptions: { exact: true },
          },
          { separator: true },
          { 
            id: "S3_5",
            label: this.translateService.instant('menu.stock.stocks') ,
            disabled: !this.hasFullRights() && !this.hasAccess('KESZLETEK'),
            routerLink: ['/stock-view'],
            routerLinkActiveOptions: { exact: true },
          },
          { 
            id: "S3_6",
            label: this.translateService.instant('menu.stock.stocksByProduct') ,
            disabled: !this.hasFullRights() && !this.hasAccess('KESZLETEK'),
            routerLink: ['/stock-by-product-view'],
            routerLinkActiveOptions: { exact: true },
          },
          { 
            id: "S3_7",
            label: this.translateService.instant('menu.stock.stockMovementLines') ,
            disabled: !this.hasFullRights() && !this.hasAccess('KESZMOZGTET'),
            routerLink: ['/stock-movement-line-view'],
            routerLinkActiveOptions: { exact: true },
          },
          { separator: true },
          { 
            id: "S3_8",
            label: this.translateService.instant('menu.stock.production') ,
            disabled: !this.hasFullRights() && !this.hasAccess('UZEMIJK'),
            routerLink: ['/production'],
            routerLinkActiveOptions: { exact: true },
          },
          { separator: true },
          { 
            id: "S3_9",
            label: this.translateService.instant('menu.stock.stockTake') ,
            disabled: !this.hasFullRights() && !this.hasAccess('KESZLELTAR'),
            routerLink: ['/stock-take'],
            routerLinkActiveOptions: { exact: true },
          },
          { 
            id: "S3_10",
            label: this.translateService.instant('menu.stock.stockTakeRecord') ,
            disabled: !this.hasFullRights() && !this.hasAccess('KESZLELTAR'),
            routerLink: ['/stock-take-record'],
            routerLinkActiveOptions: { exact: true },
          },
          { separator: true  },
          {
            id: "M3_1",
            label: this.translateService.instant('menu.stock.lists'),
            disabled: !this.hasFullRights() && !this.hasAccess('MENU_KESZLIST'),
            items: [
              { 
                id: "S3_1_1",
                label: this.translateService.instant('menu.stock.picklist') ,
                disabled: !this.hasFullRights() && !this.hasAccess('KESZKISZLIS'),
                routerLink: ['/reports-filter/picklist-rf'],
                routerLinkActiveOptions: { exact: true },
              },
              { 
                id: "S3_1_2",
                label: this.translateService.instant('menu.stock.quantityToOrderList'),
                disabled: !this.hasFullRights() && !this.hasAccess('RENDMENNYLIS'),
                routerLink: ['/reports-filter/order-list-rf'],
                routerLinkActiveOptions: { exact: true },
              },
              { 
                id: "S3_1_3",
                label: this.translateService.instant('menu.stock.orderOutByPartner'),
                disabled: !this.hasFullRights() && !this.hasAccess('BESZERZLIS'),
                routerLink: ['/reports-filter/order-out-rf'],
                queryParams: {'listType': 'PARTNER'},
                routerLinkActiveOptions: { exact: true },
              },
              { 
                id: "S3_1_4",
                label: this.translateService.instant('menu.stock.orderOutByProduct'),
                disabled: !this.hasFullRights() && !this.hasAccess('BESZERZLIS'),
                routerLink: ['/reports-filter/order-out-rf'],
                queryParams: {'listType': 'PRODUCT'},
                routerLinkActiveOptions: { exact: true },
              },
              { 
                id: "S3_1_5",
                label: this.translateService.instant('menu.stock.productTracking'),
                disabled: !this.hasFullRights() && !this.hasAccess('NYOMONKOVETES'),
                routerLink: ['/reports-filter/product-tracking-rf'],
                routerLinkActiveOptions: { exact: true },
              },
            ],
          },
        ],
      },     
      {
        id: "M4",
        label: this.translateService.instant('menu.invoices.main'),
        visible: this.isAuthenticated() && this.isApplicationSelected() && (this.hasFullRights() || this.hasAccess('MENU_SZAMLA')),
        items: [
          { 
            id: "S4_0",
            label: this.translateService.instant('menu.invoices.contract'),
            disabled: !this.hasFullRights() && !this.hasAccess('SZERZODES'),
            routerLink: ['/contract'],
            visible: !this.isCommerce(),
          },
          { 
            id: "S4_8",
            label: this.translateService.instant('menu.invoices.contractAccount'),
            disabled: !this.hasFullRights() && !this.hasAccess('ORAELSZ'),
            routerLink: ['/contract-account'],
            visible: this.hasAccess('ORAELSZ', false),
          },
          {
            id: "M4_0",
            label: this.translateService.instant('menu.invoices.customerAdvance'),
            disabled: !this.hasFullRights() && !this.hasAccess('MENU_VEVO_ELOLEG'),
            visible: false,
            items: [
              { 
                id: "S4_0_1",
                label: this.translateService.instant('menu.invoices.depositRequest'),
                disabled: !this.hasFullRights() && !this.hasAccess('ELOLEGBEKERO'),
                visible: false,
              },
              { 
                id: "S4_0_2",
                label: this.translateService.instant('menu.invoices.advanceInvoice'),
                disabled: !this.hasFullRights() && !this.hasAccess('ELOLEGSZAMLA'),
                visible: false,
              },
              { separator: true },
              { 
                id: "S4_0_3",
                label: this.translateService.instant('menu.invoices.customerAdvanceCurrentAccount'),
                disabled: !this.hasFullRights() && !this.hasAccess('VEVO_ELOLEG_FOLYOSZAMLA'),
                visible: false,
              },
            ],
          },
          // {
          //   label: this.translateService.instant('menu.invoices.importOutgoingInvoices'),
          //   routerLink: ['import-invoice-out'],
          //   visible: this.hasAccess('SZLAK_BEOLVASAS'),
          //   routerLinkActiveOptions: { exact: true },
          // },
          {
            id: "S4_1",
            label: this.translateService.instant('menu.invoices.outgoingInvoice'),
            routerLink: ['/invoice-out'],
            queryParams: {'invoiceType': 'EG'},
            disabled: !this.hasFullRights() && !this.hasAccess('SZLAK'),
            routerLinkActiveOptions: { exact: true },
          },
          { 
            id: "S4_2",
            label: this.translateService.instant('menu.invoices.outgoingInvoiceLines'),
            disabled: !this.hasFullRights() && !this.hasAccess('SZLAK'),
            routerLink: ['/invoice-out-line-view'],
            routerLinkActiveOptions: { exact: true },
          },
          { 
            id: "S4_3",
            label: this.translateService.instant('menu.invoices.outgoingInvoiceAccountingDocument'),
            routerLink: ['/invoice-out'],
            queryParams: {'invoiceType': 'SB'},
            disabled: !this.hasFullRights() && !this.hasAccess('KONYVELESI_BIZONYLAT'),
            visible: this.hasAccess('KONYVELESI_BIZONYLAT', false),
          },
          { 
            id: "S4_4",
            label: this.translateService.instant('menu.invoices.outgoingReceipt'),
            routerLink: ['/invoice-out'],
            queryParams: {'invoiceType': 'NY'},
            disabled: !this.hasFullRights() && !this.hasAccess('NYUGTA'),
            visible: this.hasAccess('NYUGTA',false),
          },
          { 
            id: "S4_5",
            label: this.translateService.instant('menu.invoices.outgoingInvoicePreparations'),
            routerLink: ['/fox-szlak-upload'],
            disabled: !this.hasFullRights() && !this.hasAccess('FOX_SZLAK_IMP'),
            visible: this.hasAccess('FOX_SZLAK_IMP', false),
          },
          { 
            id: "S4_6",
            label: this.translateService.instant('menu.invoices.invoiceOutCashPayment'),
            routerLink: ['/invoice-out-cash-payment'],
            disabled: !this.hasFullRights() && !this.hasAccess('KP_SZAMLAFIZETESEK'),
            routerLinkActiveOptions: { exact: true },
          },
          { 
            id: "S4_7",
            label: this.translateService.instant('menu.invoices.navIncomingInvoice'),
            disabled: !this.hasFullRights() && !this.hasAccess('NAV_SZLAB'),
            routerLink: ['/invoice-nav-in'],
            routerLinkActiveOptions: { exact: true },
          },
          { 
            id: "S4_8",
            label: this.translateService.instant('menu.invoices.incomingInvoice'),
            disabled: !this.hasFullRights() && !this.hasAccess('SZLAB'),
            routerLink: ['/invoice-in'],
            routerLinkActiveOptions: { exact: true },
          },
          { 
            id: "S4_9",
            label: this.translateService.instant('menu.invoices.incomingInvoiceLines'),
            disabled: !this.hasFullRights() && !this.hasAccess('SZLAB'),
            routerLink: ['/invoice-in-line-view'],
            routerLinkActiveOptions: { exact: true },
          },
          { separator: true },
          {
            id: "M4_1",
            label: this.translateService.instant('menu.invoices.currentAccount'),
            disabled: !this.hasFullRights() && !this.hasAccess('MENU_SZLA_FOLYSZLA'),
            items: [
              {
                id: "M4_1_0",
                label: this.translateService.instant('menu.invoices.buyer'),
                disabled: !this.hasFullRights() && !this.hasAccess('MENU_SZLA_FOLYSZLA_VEVO'),
                items: [
                  { 
                    id: "S4_1_0_0",
                    label: this.translateService.instant('menu.invoices.buyerCurrentAccountView'),
                    disabled: !this.hasFullRights() && !this.hasAccess('VVFOLYSZLA'),
                    routerLink: ['/accounts-receivable-view'],
                    routerLinkActiveOptions: { exact: true },
                  },
                  { 
                    id: "S4_1_0_1",
                    label: this.translateService.instant('menu.invoices.buyerCurrentAccount'),
                    disabled: !this.hasFullRights() && !this.hasAccess('VVFOLYSZLA'),
                    routerLink: ['/reports-filter/partner-account-rf', 'receivable'],
                    routerLinkActiveOptions: { exact: true },
                  },
                  {
                    id: "S4_1_0_2",
                    label: this.translateService.instant('menu.invoices.customerBalanceCommunicator'),
                    disabled: !this.hasFullRights() && !this.hasAccess('VEVO_EK'),
                    routerLink: ['/balance-statement', BalanceStatementType.VEVO_EK],
                    routerLinkActiveOptions: { exact: true },
                  },
                  {
                    id: "S4_1_0_3",
                    label: this.translateService.instant('menu.invoices.settlementOfSmallDifferences'),
                    disabled: !this.hasFullRights() && !this.hasAccess('KISREND_VE'),
                    routerLink: ['/gl-booking' ],
                    queryParams: {'bookingType': BookingType.KISREND_VE},
                    routerLinkActiveOptions: { exact: true },
                  },
                  {
                    id: "S4_1_0_4",
                    label: this.translateService.instant('menu.invoices.paymentReminder'),
                    disabled: !this.hasFullRights() && !this.hasAccess('FIZFEL'),
                    routerLink: ['/balance-statement', BalanceStatementType.FIZFEL],
                    routerLinkActiveOptions: { exact: true },
                  },
                  { 
                    id: "S4_1_0_5",
                    label: this.translateService.instant('menu.invoices.defaultInterestList'),
                    disabled: !this.hasFullRights() && !this.hasAccess('KESKAMLIS'),
                    visible: false
                  },
                  { 
                    id: "S4_1_0_6",
                    label: this.translateService.instant('menu.invoices.defaultInterestAccounting'),
                    disabled: !this.hasFullRights() && !this.hasAccess('KESKAMELSZ'),
                    visible: false
                  },
                  {
                    id: "S4_1_0_7",
                    label: this.translateService.instant('menu.invoices.pendingSettlement'),
                    disabled: !this.hasFullRights() && !this.hasAccess('FOLYSZLA_FUGGO_REND'),
                    routerLink: ['/pending-settlement'],
                    queryParams: {'settlementType': 'VE'},
                    routerLinkActiveOptions: { exact: true },
                  },
                  {
                    id: "S4_1_0_8",
                    label: this.translateService.instant('menu.invoices.agentReport'),
                    disabled: !this.hasFullRights() && !this.hasAccess('UGYNOKI_ELSZAMOLAS'),
                    routerLink: ['/gl-booking' ],
                    queryParams: {'bookingType': BookingType.UGYNOKI_ELSZAMOLAS},
                    routerLinkActiveOptions: { exact: true },
                    visible: this.globalsService.getParam('AppId') === 'f9f8781b-2877-4fdb-9dcf-57e787ef33ca'
                  },
                ]
              },
              {
                id: "M4_1_1",
                label: this.translateService.instant('menu.invoices.supplier'),
                disabled: !this.hasFullRights() && !this.hasAccess('MENU_SZLA_FOLYSZLA_SZALL'),
                items: [
                  { 
                    id: "S4_1_1_0",
                    label: this.translateService.instant('menu.invoices.supplierCurrentAccountView'),
                    disabled: !this.hasFullRights() && !this.hasAccess('SZFOLYSZLA'),
                    routerLink: ['/accounts-payable-view'],
                    routerLinkActiveOptions: { exact: true },
                  },
                  { 
                    id: "S4_1_1_0",
                    label: this.translateService.instant('menu.invoices.supplierCurrentAccount'),
                    disabled: !this.hasFullRights() && !this.hasAccess('SZFOLYSZLA'),
                    routerLink: ['/reports-filter/partner-account-rf', 'payable'],
                    routerLinkActiveOptions: { exact: true },
                  },
                  {
                    id: "S4_1_1_1",
                    label: this.translateService.instant('menu.invoices.supplierBalanceCommunicator'),
                    disabled: !this.hasFullRights() && !this.hasAccess('SZALL_EK'),
                    routerLink: ['/balance-statement', BalanceStatementType.SZALL_EK],
                    routerLinkActiveOptions: { exact: true },
                  },
                  {
                    id: "S4_1_1_2",
                    disabled: !this.hasFullRights() && !this.hasAccess('KISREND_SZ'),
                    label: this.translateService.instant('menu.invoices.settlementOfSmallDifferences'),
                    routerLink: ['/gl-booking'],
                    queryParams: {'bookingType': BookingType.KISREND_SZ},
                    routerLinkActiveOptions: { exact: true },
                  },
                  {
                    id: "S4_1_1_3",
                    label: this.translateService.instant('menu.invoices.pendingSettlement'),
                    disabled: !this.hasFullRights() && !this.hasAccess('FOLYSZLA_FUGGO_REND'),
                    routerLink: ['/pending-settlement'],
                    queryParams: {'settlementType': 'SZ'},
                    routerLinkActiveOptions: { exact: true },
                  },

                ]
              },
              {
                id: "S4_1_0",
                label: this.translateService.instant('menu.invoices.accountTransition'),
                disabled: !this.hasFullRights() && !this.hasAccess('FOLYSZLA_ATVEZ'),
                routerLink: ['/gl-booking'],
                queryParams: {'bookingType': BookingType.ATVEZETES},
                routerLinkActiveOptions: { exact: true },
              },
              {
                id: "S4_1_1",
                label: this.translateService.instant('menu.invoices.revaluationOfCurrencyReceivablesAndDebt'),
                disabled: !this.hasFullRights() && !this.hasAccess('DEV_KOV_KOT_ATERT'),
                routerLink: ['/gl-booking'],
                queryParams: {'bookingType': BookingType.ATERTEKELES},
                routerLinkActiveOptions: { exact: true },
              },
              {
                id: "C4_1_2",
                label: this.translateService.instant('menu.invoices.collectCurrentAccountData'),
                disabled: !this.hasFullRights() && !this.hasAccess('FOLYSZLAGYUJT'),
                command: () => this.loadAccountsBalanceArrangeDialog(),
              },
            ],
          },
          // { separator: true },
          {
            id: "M4_2",
            label: this.translateService.instant('menu.invoices.invoiceReports'),
            disabled: !this.hasFullRights() && !this.hasAccess('MENU_SZLA_LISTAK'),
            visible: false,
            items: [
              { 
                id: "S4_2_0",
                label: this.translateService.instant('menu.invoices.listOfOutgoingInvoices'),
                disabled: !this.hasFullRights() && !this.hasAccess('SZLAKLIS'),
                visible: false
              },
              { 
                id: "S4_2_1",
                label: this.translateService.instant('menu.invoices.listOfIncomingInvoices'),
                disabled: !this.hasFullRights() && !this.hasAccess('SZLABLIS'),
                visible: false
              },
              { 
                id: "S4_2_2",
                label: this.translateService.instant('menu.invoices.receivablesTable'),
                disabled: !this.hasFullRights() && !this.hasAccess('KINTLEV'),
                visible: false
              },
              { 
                id: "S4_2_3",
                label: this.translateService.instant('menu.invoices.depreciationAccounting'),
                disabled: !this.hasFullRights() && !this.hasAccess('ERTVESZT_ELSZ'),
                visible: false
              },
              { 
                id: "S4_2_4",
                label: this.translateService.instant('menu.invoices.listOfOutgoingInvoicesByService'),
                disabled: !this.hasFullRights() && !this.hasAccess('SZLAKSZOLGLIS'),
                visible: false
              },
              { 
                id: "S4_2_5",
                label: this.translateService.instant('menu.invoices.revenuePerMonth'),
                disabled: !this.hasFullRights() && !this.hasAccess('HAVIARBEV'),
                visible: false
              },
              { 
                id: "S4_2_6",
                label: this.translateService.instant('menu.invoices.revenuePerYearPerCustomer'),
                disabled: !this.hasFullRights() && !this.hasAccess('ARBEVEVPARTNER'),
                visible: false
              },
              { 
                id: "S4_2_7",
                label: this.translateService.instant('menu.invoices.liabilityTable'),
                disabled: !this.hasFullRights() && !this.hasAccess('KOTTAB'),
                visible: false
              },
              { 
                id: "S4_2_8",
                label: this.translateService.instant('menu.invoices.scheduledCashFlows'),
                disabled: !this.hasFullRights() && !this.hasAccess('UTPENZMOZG'),
                visible: false
              },
              { 
                id: "S4_2_9",
                label: this.translateService.instant('menu.invoices.financialOutlook'),
                disabled: !this.hasFullRights() && !this.hasAccess('VPH'),
                visible: false
              },
            ],
          },
          // { separator: true },
          { 
            id: "S4_5",
            label: this.translateService.instant('menu.invoices.taxAuthorityAuditReporting'),
            disabled: !this.hasFullRights() && !this.hasAccess('NAVADATSZOLG'),
            visible: false
          },
        ],
      },
      {
        id: "M5",
        label: this.translateService.instant('menu.bank.main'),
        visible: this.isAuthenticated() && this.isApplicationSelected() && (this.hasFullRights() || this.hasAccess('MENU_BANK')),
        items: [
          {
            id: "S5_0",
            label: this.translateService.instant('menu.bank.bankStatement'),
            disabled: !this.hasFullRights() && !this.hasAccess('KIVONAT'),
            routerLink: ['/bank-statement'],
            routerLinkActiveOptions: { exact: true },
          },
          { 
            id: "S5_1",
            label: this.translateService.instant('menu.bank.queryingBankItems'),
            disabled: !this.hasFullRights() && !this.hasAccess('BANKTET'),
            routerLink: ['/bank-statement-line-view'],
            routerLinkActiveOptions: { exact: true },
          },
          { separator: true },
          { 
            id: "S5_2",
            label: this.translateService.instant('menu.bank.bankTransfer'),
            disabled: !this.hasFullRights() && !this.hasAccess('ATUT'),
            routerLink: ['/bank-transfer-order', BankTransferOrderCategory.FORINT ],
            routerLinkActiveOptions: { exact: true },
          },
          { 
            id: "S5_3",
            label: this.translateService.instant('menu.bank.bankTransferInForeignCurrency'),
            disabled: !this.hasFullRights() && !this.hasAccess('DATUT'),
            routerLink: ['/bank-transfer-order', BankTransferOrderCategory.DEVIZA ],
            routerLinkActiveOptions: { exact: true },
          },
          {
            id: "S5_4",
            label: this.translateService.instant('menu.bank.exchangeRate'),
            disabled: !this.hasFullRights() && !this.hasAccess('ARF'),
            routerLink: ['/exchange-rate'],
            routerLinkActiveOptions: { exact: true },
          },
        ],
      },
      {
        id: "M6",
        label: this.translateService.instant('menu.pettyCash.main'),
        visible: this.isAuthenticated() && this.isApplicationSelected() && (this.hasFullRights() || this.hasAccess('MENU_PENZTAR')),
        items: [
          {
            id: "S6_0",
            label: this.translateService.instant('menu.pettyCash.cashRecords'),
            disabled: !this.hasFullRights() && !this.hasAccess('PTROGZ'),
            routerLink: ['/cash-transaction'],
            routerLinkActiveOptions: { exact: true },
          },
          { 
            id: "S6_1",
            label: this.translateService.instant('menu.pettyCash.closingThePettyCash'),
            disabled: !this.hasFullRights() && !this.hasAccess('PTZAR'),
            routerLink: ['/cash-day'],
            routerLinkActiveOptions: { exact: true },
          },
          { 
            id: "S6_2",
            label: this.translateService.instant('menu.pettyCash.selectingAPettyCash'),
            disabled: !this.hasFullRights() && !this.hasAccess('PTVAL'),
            routerLink: ['/cash','select'],
            routerLinkActiveOptions: { exact: true },
          },
          { 
            id: "S6_3",
            label: this.translateService.instant('menu.pettyCash.cashSettlement'),
            disabled: !this.hasFullRights() && !this.hasAccess('NAPIKPELSZ'),
            routerLink: ['/reports-filter/cash-settlement-rf'],
            routerLinkActiveOptions: { exact: true },
            visible: this.hasAccess('NAPIKPELSZ', false)
          },
          { 
            id: "S6_4",
            label: this.translateService.instant('menu.pettyCash.cashGen'),
            disabled: !this.hasFullRights() && !this.hasAccess('NAPIKPGEN'),
            routerLink: ['/reports-filter/cash-gen'],
            routerLinkActiveOptions: { exact: true },
            visible: this.hasAccess('NAPIKPGEN', false)
          },
          // { separator: true },
          { 
            id: "S6_5",
            label: this.translateService.instant('menu.pettyCash.periodicCashbookReport'),
            disabled: !this.hasFullRights() && !this.hasAccess('PTJEL'),
            routerLink: ['/reports-filter/cash-report-rf'],
            routerLinkActiveOptions: { exact: true },
          },

          // { label: this.translateService.instant('menu.pettyCash.averageCashHoldings') },
          { separator: true },
          { 
            id: "S6_6",
            label: this.translateService.instant('menu.pettyCash.cashItems') ,
            disabled: !this.hasFullRights() && !this.hasAccess('PTTET'),
            routerLink: ['/cash-transaction-line-view'],
            routerLinkActiveOptions: { exact: true },
          },
        ],
      },
      {
        id: "M7",
        label: this.translateService.instant('menu.assets.main'),
        visible: this.isAuthenticated() && this.isApplicationSelected() && (this.hasFullRights() || this.hasAccess('MENU_ESZKOZ')),
        items: [
          { 
            id: "S7_0",
            label: this.translateService.instant('menu.assets.maintenanceOfAssetsData'),
            disabled: !this.hasFullRights() && !this.hasAccess('ESZKARB'),
            routerLink: ['/asset'],
            routerLinkActiveOptions: { exact: true },
          },
          { separator: true },
          { 
            id: "S7_1",
            label: this.translateService.instant('menu.assets.capitalisation'),
            disabled: !this.hasFullRights() && !this.hasAccess('ESZKAKT'),
            routerLink: ['/asset-event', AssetEventType.AKTIVALAS ],
            routerLinkActiveOptions: { exact: true },
          },
          { 
            id: "S7_2",
            label: this.translateService.instant('menu.assets.accountingForDepreciation'),
            disabled: !this.hasFullRights() && !this.hasAccess('ESZKECS'),
            routerLink: ['/asset-event', AssetEventType.ERTEKCSOKKENES ],
            routerLinkActiveOptions: { exact: true },
          },
          { 
            id: "S7_3",
            label: this.translateService.instant('menu.assets.acceleratedDepreciation'),
            disabled: !this.hasFullRights() && !this.hasAccess('ESZKTFECS'),
            routerLink: ['/asset-event', AssetEventType.TERVEN_FELULI_ECS ],
            routerLinkActiveOptions: { exact: true },
          },
          { 
            id: "S7_4",
            label: this.translateService.instant('menu.assets.valueAdjustment'),
            disabled: !this.hasFullRights() && !this.hasAccess('ESZKERTH'),
            routerLink: ['/asset-event', AssetEventType.ERTEKHELYESBITES ],
            routerLinkActiveOptions: { exact: true },
          },
          { 
            id: "S7_5",
            label: this.translateService.instant('menu.assets.scrapping'),
            disabled: !this.hasFullRights() && !this.hasAccess('ESZKSEL'),
            routerLink: ['/asset-event', AssetEventType.SELEJTEZES ],
            routerLinkActiveOptions: { exact: true },
          },
          { 
            id: "S7_6",
            label: this.translateService.instant('menu.assets.sales'),
            disabled: !this.hasFullRights() && !this.hasAccess('ESZKERT'),
            routerLink: ['/asset-event', AssetEventType.ERTEKESITES ],
            routerLinkActiveOptions: { exact: true },
          },
          { 
            id: "S7_7",
            label: this.translateService.instant('menu.assets.transfersBetweenAssets'),
            disabled: !this.hasFullRights() && !this.hasAccess('ESZKATVEZ'),
            routerLink: ['/asset-event', AssetEventType.ATVEZETES ],
            routerLinkActiveOptions: { exact: true },
          },
          { 
            id: "S7_8",
            label: this.translateService.instant('menu.assets.transferBetweenTypesOfAssets'),
            disabled: !this.hasFullRights() && !this.hasAccess('ESZKTIPATVEZ'),
            routerLink: ['/asset-event', AssetEventType.ESZKOZTIPUS_ATVEZ ],
            routerLinkActiveOptions: { exact: true },
          },
          { separator: true },
          { 
            id: "S7_9",
            label: this.translateService.instant('menu.assets.assetEvents'),
            disabled: !this.hasFullRights() && !this.hasAccess('ESZKESEM'),
            routerLink: ['/asset-event-line-view'],
            routerLinkActiveOptions: { exact: true },
          },
          { separator: true },
          {
            id: "M7_0",
            label: this.translateService.instant('menu.assets.listsDocuments'),
            disabled: !this.hasFullRights() && !this.hasAccess('MENU_ESZKLIST'),
            items: [
              { 
                id: "S7_0_0",
                label: this.translateService.instant('menu.assets.purchasingListOfAssets') ,
                disabled: !this.hasFullRights() && !this.hasAccess('ESZKBESZLIS'),
                routerLink: ['/reports-filter/asset-purchase-rf'],
                routerLinkActiveOptions: { exact: true },
              },
              { 
                id: "S7_0_1",
                label: this.translateService.instant('menu.assets.consignmentDocument'),
                disabled: !this.hasFullRights() && !this.hasAccess('ESZKAVBIZ'),
                visible: false,
              },
              { 
                id: "S7_0_2",
                label: this.translateService.instant('menu.assets.assetsFile') ,
                disabled: !this.hasFullRights() && !this.hasAccess('ESZKKART'),
                routerLink: ['/reports-filter/asset-statement-rf'],
                routerLinkActiveOptions: { exact: true },
              },
              { 
                id: "S7_0_3",
                label: this.translateService.instant('menu.assets.listOfAssets') ,
                disabled: !this.hasFullRights() && !this.hasAccess('ESZKLISTA'),
                routerLink: ['/reports-filter/asset-registered-rf'],
                routerLinkActiveOptions: { exact: true },
              },
              { 
                id: "S7_0_4",
                label: this.translateService.instant('menu.assets.chartOfAssets') ,
                disabled: !this.hasFullRights() && !this.hasAccess('ESZKTUKOR'),
                routerLink: ['/reports-filter/asset-analysis-rf'],
                routerLinkActiveOptions: { exact: true },
              },
              // { separator: true },
              { 
                id: "S7_0_5",
                label: this.translateService.instant('menu.assets.depreciationOfDerecognisedAssets'),
                disabled: !this.hasFullRights() && !this.hasAccess('ESZKKIVEZECS'),
                visible: false,
              },
            ],
          },
        ],
      },
      {
        id: "M8",
        label: this.translateService.instant('menu.generalLedger.main'),
        visible: this.isAuthenticated() && this.isApplicationSelected() && (this.hasFullRights() || this.hasAccess('MENU_FKV')),
        items: [
          {
            id: "S8_0",
            label: this.translateService.instant('menu.generalLedger.mixedAccounting'),
            disabled: !this.hasFullRights() && !this.hasAccess('VEGYES'),
            routerLink: ['/gl-booking'],
            queryParams: {'bookingType': BookingType.VEGYES},
            routerLinkActiveOptions: { exact: true },
          },
          // { label: this.translateService.instant('menu.generalLedger.accrualsAccounting') },
          // { label: this.translateService.instant('menu.generalLedger.transfers') },
          {
            id: "S8_1",
            label: this.translateService.instant('menu.generalLedger.accountAssignment'),
            disabled: !this.hasFullRights() && !this.hasAccess('KONTIRNYOM'),
            routerLink: ['/reports-filter/account-assignment-rf'],
            routerLinkActiveOptions: { exact: true },
            // items: [
            //   { label: this.translateService.instant('menu.invoices.incomingInvoice') },
            //   { label: this.translateService.instant('menu.invoices.outgoingInvoice') },
            //   { label: this.translateService.instant('menu.bank.main') },
            //   { label: this.translateService.instant('menu.pettyCash.main') },
            //   { label: this.translateService.instant('menu.generalLedger.mixedAccounting') },
            //   { label: this.translateService.instant('menu.generalLedger.transfers') },
            // ],
          },
          {
            id: "M8_0",
            label: this.translateService.instant('menu.generalLedger.vat'),
            disabled: !this.hasFullRights() && !this.hasAccess('MENU_FKV_AFA'),
            items: [
              // { label: this.translateService.instant('menu.generalLedger.mixedVatAccounting') },
              // { separator: true },
              { 
                id: "S8_0_0",
                label: this.translateService.instant('menu.generalLedger.vatList'),
                disabled: !this.hasFullRights() && !this.hasAccess('AFA_65A'),
                routerLink: ['/vat-subledger-view'],
                routerLinkActiveOptions: { exact: true },
              },
              // { label: this.translateService.instant('menu.generalLedger.domesticSupplierInvoices') },
              { 
                id: "S8_0_1",
                label: this.translateService.instant('menu.generalLedger.communityVat'),
                disabled: !this.hasFullRights() && !this.hasAccess('AFA_60A'),
                routerLink: ['/vat-a-60-view'],
                routerLinkActiveOptions: { exact: true },
              },
              // { label: this.translateService.instant('menu.generalLedger.vatClosing') },
              // { label: this.translateService.instant('menu.generalLedger.vatAndAccountingYearDifferences') },
            ],
          },
          { 
            id: "S8_2",
            label: this.translateService.instant('menu.generalLedger.kivaDeclaration'),
            disabled: !this.hasFullRights() && !this.hasAccess('FKV_KIVA'),
            routerLink: ['/kiva-view'],
            routerLinkActiveOptions: { exact: true },
            visible: true,
          },
          { 
            id: "S8_3",
            label: this.translateService.instant('menu.generalLedger.invoiceDataToNav'),
            disabled: !this.hasFullRights() && !this.hasAccess('FKV_PTGSZLAH'),
            visible: false,
          },
          {
            id: "M8_1",
            label: this.translateService.instant('menu.generalLedger.currentYearAccounting'),
            disabled: !this.hasFullRights() && !this.hasAccess('MENU_FKV_AKTEV'),
            items: [
              {
                id: "M8_1_0",
                label: this.translateService.instant('menu.generalLedger.post'),
                disabled: !this.hasFullRights() && !this.hasAccess('MENU_FKVFELAD'),
                items: [
                  { 
                    id: "S8_1_0_0",
                    label: this.translateService.instant('menu.generalLedger.post'),
                    disabled: !this.hasFullRights() && !this.hasAccess('FKV_FELAD'),
                    routerLink: ['/reports-filter/gl-post-revoke-f','Post'],
                    routerLinkActiveOptions: { exact: true },
                  },
                  { separator: true },
                  { 
                    id: "S8_1_0_1",
                    label: this.translateService.instant('menu.generalLedger.fullAudit'),
                    disabled: !this.hasFullRights() && !this.hasAccess('FKV_ELL'),
                    routerLink: ['/reports-filter/gl-post-revoke-f','Audit'],
                    routerLinkActiveOptions: { exact: true },
                  },
                  // { separator: true },
                  { 
                    id: "S8_1_0_2",
                    label: this.translateService.instant('menu.generalLedger.listOfNon-admitted'),
                    disabled: !this.hasFullRights() && !this.hasAccess('FKV_FELNEMADOTTLISTA'),
                    visible: false,
                  },
                ],
              },
              { 
                id: "S8_1_0",
                label: this.translateService.instant('menu.generalLedger.revoke'),
                disabled: !this.hasFullRights() && !this.hasAccess('FKV_LEVET'),
                routerLink: ['/reports-filter/gl-post-revoke-f','Revoke'],
                routerLinkActiveOptions: { exact: true },
              },
              { separator: true },
              { 
                id: "S8_1_2",
                label: this.translateService.instant('menu.generalLedger.chartOfAccountsAndAnAccountTableCreating'),
                disabled: !this.hasFullRights() && !this.hasAccess('SZLATUK_KTGEN'),
                visible: false,
              },
              { 
                id: "C8_1_3",
                label: this.translateService.instant('menu.generalLedger.createAndCompleteChartOfAccountsBasedOnThePreviousYear'),
                disabled: !this.hasFullRights() && !this.hasAccess('SZLATUKGEN'),
                command: () => this.loadGlStatementGenerateDialog(),
                // routerLink: ['/gl-statement/generate'],
                // routerLinkActiveOptions: { exact: true },
              },
              { 
                id: "S8_1_4",
                label: this.translateService.instant('menu.generalLedger.generalLedgerNumberConversion'),
                disabled: !this.hasFullRights() && !this.hasAccess('FKVSZKONV'),
                visible: false,
              },
              {
                id: "C8_1_5",
                label: this.translateService.instant('menu.generalLedger.annualOpeningDataMigration'),
                disabled: !this.hasFullRights() && !this.hasAccess('NYITOATTOLT'),
                command: () => this.loadGlStatementLoadOpeningDialog(),
              },
              { 
                id: "C8_1_6",
                label: this.translateService.instant('menu.generalLedger.closingOfTheGeneralLedgerYear'),
                disabled: !this.hasFullRights() && !this.hasAccess('FKVZAR'),
                command: () => this.loadGlStatementCloseYearDialog(),
              },
            ],
          },
          // {
          //   id: "S8_4",
          //   label: this.translateService.instant('menu.generalLedger.currentAccountToGeneralLedgerReconciliation'),
          //   disabled: !this.hasFullRights() && !this.hasAccess('FOLYSZLAFKVEGYEZ'),
          //   routerLink: ['/product'],
          //   routerLinkActiveOptions: { exact: true },
          // },
          {
            id: "S8_5",
            label: this.translateService.instant('menu.generalLedger.balanceSheetAndIncomeStatement'),
            disabled: !this.hasFullRights() && !this.hasAccess('MERLEG'),
            routerLink: ['/gl-report'],
            routerLinkActiveOptions: { exact: true },
          },
          // { separator: true },
          { 
            id: "S8_6",
            label: this.translateService.instant('menu.generalLedger.businessPlanFacts'),
            disabled: !this.hasFullRights() && !this.hasAccess('UZLETITERV'),
            visible: false,
          },
          // { separator: true },
          {
            id: "M8_2",
            label: this.translateService.instant('menu.generalLedger.query'),
            disabled: !this.hasFullRights() && !this.hasAccess('MENU_FKVLEK'),
            items: [
              { 
                id: "S7_2_0",
                label: this.translateService.instant('menu.generalLedger.chartOfAccounts'),
                disabled: !this.hasFullRights() && !this.hasAccess('SZLATUK'),
                visible: false,
              },
              { 
                id: "S7_2_1",
                label: this.translateService.instant('menu.generalLedger.journal'),
                disabled: !this.hasFullRights() && !this.hasAccess('NAPLO'),
                routerLink: ['/gl-journal-view'],
                routerLinkActiveOptions: { exact: true },
              },
              { separator: true },
              { 
                id: "S7_2_2",
                label: this.translateService.instant('menu.generalLedger.generalLedgerSummary'),
                disabled: !this.hasFullRights() && !this.hasAccess('FKVOSSZ'),
                visible: false,
              },
              {
                id: "S7_2_3",
                label: this.translateService.instant('menu.generalLedger.generalLedgerStatement'),
                disabled: !this.hasFullRights() && !this.hasAccess('FKVKIVONAT'),
                routerLink: ['/reports-filter/gl-statement-rf'],
                routerLinkActiveOptions: { exact: true },
              },
              { 
                id: "S7_2_4",
                label: this.translateService.instant('menu.generalLedger.generalLedgerStatementForThePeriod'),
                disabled: !this.hasFullRights() && !this.hasAccess('FKVKIVONATIDOSZAK'),
                routerLink: ['/reports-filter/gl-statement-period-rf'],
                routerLinkActiveOptions: { exact: true },
              },
              { 
                id: "S7_2_5",
                label: this.translateService.instant('menu.generalLedger.generalLedgerFiles'),
                disabled: !this.hasFullRights() && !this.hasAccess('FKVKARTON'),
                routerLink: ['/reports-filter/gl-statement-register-rf'], 
                routerLinkActiveOptions: { exact: true },
              },
              { 
                id: "S7_2_6",
                label: this.translateService.instant('menu.generalLedger.glNotPostedReceipts'),
                disabled: !this.hasFullRights() && !this.hasAccess('FKV_FELNEMADOTTLISTA'),
                routerLink: ['/reports-filter/gl-not-posted-receipts-rf'], 
                routerLinkActiveOptions: { exact: true },
              },
              { 
                id: "S7_2_7",
                label: this.translateService.instant('menu.generalLedger.costCenterList'),
                disabled: !this.hasFullRights() && !this.hasAccess('FKV_KTSGHELYLISTA'),
                routerLink: ['/reports-filter/cost-center-rf'], 
                routerLinkActiveOptions: { exact: true },
              },
            ],
          },
      // { separator: true },
          // {
          //   label: this.translateService.instant('menu.generalLedger.query'),
          //   items: [
          //     {
          //       label: this.translateService.instant('menu.generalLedger.generalLedgerStatement'),
          //       routerLink: ['/reports-filter/gl-statement-rf'],
          //       routerLinkActiveOptions: { exact: true },
          //     },
          //   ],
          // },
        ],
      },
      {
        id: "M9",
        label: this.translateService.instant('menu.vehicles.main'),
        visible: this.isAuthenticated() && this.isApplicationSelected() && (this.hasFullRights() || this.hasAccess('MENU_GEPKOCSI')),
        items: [
          { 
            id: "S9_0",
            label: this.translateService.instant('menu.vehicles.detailsOfVehicles'),
            disabled: !this.hasFullRights() && !this.hasAccess('GKADAT'),
            routerLink: ['/vehicle'],
            routerLinkActiveOptions: { exact: true },
          },
          { 
            id: "S9_1",
            label: this.translateService.instant('menu.vehicles.ownCarUse'),
            disabled: !this.hasFullRights() && !this.hasAccess('GKUTNYILV'),
            routerLink: ['/mileage-record'],
            routerLinkActiveOptions: { exact: true },
          },
        ],
      },
      {
        id: "M10",
        label: this.translateService.instant('menu.doc.main'),
        visible: this.isAuthenticated() && this.isApplicationSelected() && (this.hasFullRights() || this.hasAccess('MENU_DOKUMENTUMOK')),
        items: [
          { 
            id: "S10_0",
            label: this.translateService.instant('menu.doc.doc'),
            disabled: !this.hasFullRights() && !this.hasAccess('DOKUMENTUMOK'),
            routerLink: ['/doc'],
            routerLinkActiveOptions: { exact: true },
          },
          {
            id: "S10_1",
            label: this.translateService.instant('menu.doc.docCategory'),
            disabled: !this.hasFullRights() && !this.hasAccess('DOKTIP'),
            routerLink: ['/doc-category'],
            routerLinkActiveOptions: { exact: true },
          },
        ],
      },
      {
        id: "M11",
        label: this.translateService.instant('menu.entities.main'),
        visible: this.isAuthenticated() && this.isApplicationSelected() && (this.hasFullRights() || this.hasAccess('MENU_TORZS')),
        items: [
          {
            id: "M11_0",
            label: this.translateService.instant('menu.entities.products'),
            disabled: !this.hasFullRights() && !this.hasAccess('MENU_T_ARU'),
            items: [
              {
                id: "S11_0_0",
                label: this.translateService.instant('menu.entities.productGroup'),
                disabled: !this.hasFullRights() && !this.hasAccess('ARUCSOP'),
                routerLink: ['/product-group'],
                routerLinkActiveOptions: { exact: true },
              },
              {
                id: "S11_0_1",
                label: this.translateService.instant('menu.entities.product'),
                disabled: !this.hasFullRights() && !this.hasAccess('ARUK'),
                routerLink: ['/product'],
                routerLinkActiveOptions: { exact: true },
              },
              {
                id: "S11_0_2",
                label: this.translateService.instant('menu.entities.productPrice'),
                disabled: !this.hasFullRights() && !this.hasAccess('ARAK'),
                routerLink: ['/product-price'],
                routerLinkActiveOptions: { exact: true },
              },
              {
                id: "S11_0_3",
                label: this.translateService.instant('menu.entities.productDiscount'),
                disabled: !this.hasFullRights() && !this.hasAccess('ENGEDMENYEK'),
                routerLink: ['/product-discount'],
                routerLinkActiveOptions: { exact: true },
              },
              {
                id: "S11_0_4",
                label: this.translateService.instant('menu.entities.productAdditionalCharge'),
                disabled: !this.hasFullRights() && !this.hasAccess('FELARAK'),
                routerLink: ['/product-additional-charge'],
                routerLinkActiveOptions: { exact: true },
              },
              {
                id: "S11_0_5",
                label: this.translateService.instant('menu.entities.measuringUnit'),
                disabled: !this.hasFullRights() && !this.hasAccess('MEGYS'),
                routerLink: ['/measuring-unit'],
                routerLinkActiveOptions: { exact: true },
              },
              {
                id: "S11_0_6",
                label: this.translateService.instant('menu.entities.combinedNomenclature'),
                visible: this.accountService.hasAnyAuthority('ROLE_ADMIN'),
                disabled: !this.hasFullRights() && !this.hasAccess('VTSZ'),
                routerLink: ['/combined-nomenclature'],
                routerLinkActiveOptions: { exact: true },
              },
              {
                id: "S11_0_7",
                label: this.translateService.instant('menu.entities.teszor'),
                visible: this.accountService.hasAnyAuthority('ROLE_ADMIN'),
                disabled: !this.hasFullRights() && !this.hasAccess('TESZOR'),
                routerLink: ['/teszor'],
                routerLinkActiveOptions: { exact: true },
              },
            ],
          },
          {
            id: "M11_1",
            label: this.translateService.instant('menu.entities.partner'),
            disabled: !this.hasFullRights() && !this.hasAccess('MENU_T_PARTNER'),
            items: [
              {
                id: "S11_1_0",
                label: this.translateService.instant('menu.entities.partner'),
                disabled: !this.hasFullRights() && !this.hasAccess('PARTNEREK'),
                routerLink: ['/partner'],
                routerLinkActiveOptions: { exact: true },
              },
              {
                id: "S11_1_1",
                label: this.translateService.instant('menu.entities.partnerGroup'),
                disabled: !this.hasFullRights() && !this.hasAccess('PARTNERCSOP'),
                routerLink: ['/partner-group'],
                routerLinkActiveOptions: { exact: true },
              },
              {
                id: "S11_1_2",
                label: this.translateService.instant('menu.entities.partnerCategory'),
                disabled: !this.hasFullRights() && !this.hasAccess('PARTNERKAT'),
                routerLink: ['/partner-category'],
                routerLinkActiveOptions: { exact: true },
              },
              {
                id: "S11_1_3",
                label: this.translateService.instant('menu.entities.teaor'),
                visible: this.accountService.hasAnyAuthority('ROLE_ADMIN'),
                disabled: !this.hasFullRights() && !this.hasAccess('TEAOR'),
                routerLink: ['/teaor'],
                routerLinkActiveOptions: { exact: true },
              },
              {
                id: "S11_1_4",
                label: this.translateService.instant('menu.entities.routes'),
                disabled: !this.hasFullRights() && !this.hasAccess('TURA'),
                routerLink: ['/route'],
                routerLinkActiveOptions: { exact: true },
              },
            ],
          },
          {
            id: "M11_2",
            label: this.translateService.instant('menu.entities.financial'),
            disabled: !this.hasFullRights() && !this.hasAccess('MENU_T_PENZUGY'),
            items: [
              {
                id: "M11_2_0",
                label: this.translateService.instant('menu.entities.cash'),
                disabled: !this.hasFullRights() && !this.hasAccess('PENZTARAK'),
                routerLink: ['/cash','main'],
                routerLinkActiveOptions: { exact: true },
              },
              {
                id: "S11_2_1",
                label: this.translateService.instant('menu.entities.paymentMethod'),
                visible: this.accountService.hasAnyAuthority('ROLE_ADMIN'),
                disabled: !this.hasFullRights() && !this.hasAccess('FIZMOD'),
                routerLink: ['/payment-method'],
                routerLinkActiveOptions: { exact: true },
              },
              {
                id: "S11_2_2",
                label: this.translateService.instant('menu.entities.currency'),
                visible: this.accountService.hasAnyAuthority('ROLE_ADMIN'),
                disabled: !this.hasFullRights() && !this.hasAccess('DEVIZAK'),
                routerLink: ['/currency'],
                routerLinkActiveOptions: { exact: true },
              },
              {
                id: "S11_2_3",
                label: this.translateService.instant('menu.entities.exchangeRate'),
                disabled: !this.hasFullRights() && !this.hasAccess('ARF'),
                routerLink: ['/exchange-rate'],
                routerLinkActiveOptions: { exact: true },
              },
              {
                id: "S11_2_4",
                label: this.translateService.instant('menu.entities.vatRate'),
                disabled: !this.hasFullRights() && !this.hasAccess('AFAKULCS'),
                routerLink: ['/vat-rate'],
                routerLinkActiveOptions: { exact: true },
              },
              {
                id: "S11_2_5",
                label: this.translateService.instant('menu.entities.vatRateExemption'),
                disabled: !this.hasFullRights() && !this.hasAccess('AFAKULCS'),
                routerLink: ['/vat-rate-exemption'],
                routerLinkActiveOptions: { exact: true },
              },
              {
                id: "S11_2_6",
                label: this.translateService.instant('menu.entities.vatType'),
                disabled: !this.hasFullRights() && !this.hasAccess('AFATIP'),
                routerLink: ['/vat-type'],
                routerLinkActiveOptions: { exact: true },
              },
              {
                id: "S11_2_7",
                label: this.translateService.instant('menu.entities.navTaxCode'),
                visible: this.accountService.hasAnyAuthority('ROLE_ADMIN'),
                disabled: !this.hasFullRights() && !this.hasAccess('NAV_AFA_KOD'),
                routerLink: ['/nav-tax-code'],
                routerLinkActiveOptions: { exact: true },
              },
              {
                id: "S11_2_8",
                label: this.translateService.instant('menu.entities.priceType'),
                disabled: !this.hasFullRights() && !this.hasAccess('ARTIP'),
                routerLink: ['/price-type'],
                routerLinkActiveOptions: { exact: true },
              },
              {
                id: "S11_2_9",
                label: this.translateService.instant('menu.entities.agingCategory'),
                disabled: !this.hasFullRights() && !this.hasAccess('KORKAT'),
                routerLink: ['/aging-category'],
                routerLinkActiveOptions: { exact: true },
              },
              {
                id: "S11_2_10",
                label: this.translateService.instant('menu.entities.navParams'),
                disabled: !this.hasFullRights() && !this.hasAccess('NAV_ESZAMLA_PARAM'),
                routerLink: ['/parameter'],
                queryParams: {'paramCategory': 'NAV_ESZAMLA'},
                routerLinkActiveOptions: { exact: true },
              },
              {
                id: "S11_2_11",
                label: this.translateService.instant('menu.entities.ekaerParams'),
                disabled: !this.hasFullRights() && !this.hasAccess('EKAER_PARAM'),
                routerLink: ['/parameter'],
                queryParams: {'paramCategory': 'EKAER'},
                routerLinkActiveOptions: { exact: true },
              },
            ],
          },
          {
            id: "M11_3",
            label: this.translateService.instant('menu.entities.ledger'),
            disabled: !this.hasFullRights() && !this.hasAccess('MENU_T_FKV'),
            items: [
              {
                id: "S11_3_0",
                label: this.translateService.instant('menu.entities.glCategory'),
                disabled: !this.hasFullRights() && !this.hasAccess('JOGCIM'),
                routerLink: ['/gl-category'],
                routerLinkActiveOptions: { exact: true },
              },
              {
                id: "S11_3_1",
                label: this.translateService.instant('menu.entities.glCategorySettings'),
                disabled: !this.hasFullRights() && !this.hasAccess('JC_BEALL'),
                routerLink: ['/parameter'],
                queryParams: {'paramCategory': 'JOGCIM'},
                routerLinkActiveOptions: { exact: true },
              },
              {
                id: "S11_3_2",
                label: this.translateService.instant('menu.entities.glParams'),
                disabled: !this.hasFullRights() && !this.hasAccess('FKV_PARAM'),
                routerLink: ['/parameter'],
                queryParams: {'paramCategory': 'KONYVELES'},
                routerLinkActiveOptions: { exact: true },
              },
              {
                id: "S11_3_3",
                label: this.translateService.instant('menu.entities.glNavParams'),
                disabled: !this.hasFullRights() && !this.hasAccess('NAV_BEVALL_PARAM'),
                routerLink: ['/parameter'],
                queryParams: {'paramCategory': 'NAV_BEVALL'},
                routerLinkActiveOptions: { exact: true },
              },
              {
                id: "S11_3_4",
                label: this.translateService.instant('menu.entities.glJournalType'),
                disabled: !this.hasFullRights() && !this.hasAccess('NAPLOTIP'),
                routerLink: ['/gl-journal-type'],
                routerLinkActiveOptions: { exact: true },
              },
              {
                id: "S11_3_5",
                label: this.translateService.instant('menu.entities.glAssetAssignmentRule'),
                disabled: !this.hasFullRights() && !this.hasAccess('ESZKESEMKONT'),
                routerLink: ['/gl-asset-assignment-rule'],
                routerLinkActiveOptions: { exact: true },
              },
              {
                id: "S11_3_6",
                label: this.translateService.instant('menu.entities.glCostCenter'),
                disabled: !this.hasFullRights() && !this.hasAccess('KTGHELY'),
                routerLink: ['/gl-cost-center'],
                routerLinkActiveOptions: { exact: true },
              },
              {
                id: "S11_3_7",
                label: this.translateService.instant('menu.entities.glCostCategory'),
                disabled: !this.hasFullRights() && !this.hasAccess('KTGKAT'),
                routerLink: ['/gl-cost-category'],
                routerLinkActiveOptions: { exact: true },
              },
              {
                id: "S11_3_8",
                label: this.translateService.instant('menu.entities.glJobNumber'),
                disabled: !this.hasFullRights() && !this.hasAccess('MUNKASZAMOK'),
                routerLink: ['/gl-job-number'],
                routerLinkActiveOptions: { exact: true },
              },
              {
                id: "S11_3_9",
                label: this.translateService.instant('menu.entities.glStatement'),
                disabled: !this.hasFullRights() && !this.hasAccess('T_FKVSZLATUK'),
                routerLink: ['/gl-statement'],
                routerLinkActiveOptions: { exact: true },
              },
              {
                id: "S11_3_10",
                label: this.translateService.instant('menu.entities.glReportTemplate'),
                disabled: !this.hasFullRights() && !this.hasAccess('KIMUTSABLON'),
                routerLink: ['/gl-report-template'],
                routerLinkActiveOptions: { exact: true },
              },
            ],
          },
          {
            id: "M11_4",
            label: this.translateService.instant('menu.entities.stockMain'),
            disabled: !this.hasFullRights() && !this.hasAccess('MENU_T_KESZLET'),
            items: [
              {
                id: "S11_4_0",
                label: this.translateService.instant('menu.entities.store'),
                disabled: !this.hasFullRights() && !this.hasAccess('RAKTARAK'),
                routerLink: ['/store'],
                routerLinkActiveOptions: { exact: true },
              },
              {
                id: "S11_4_1",
                label: this.translateService.instant('menu.entities.stockMovementType'),
                disabled: !this.hasFullRights() && !this.hasAccess('MOZGNEM'),
                routerLink: ['/stock-movement-type'],
                routerLinkActiveOptions: { exact: true },
              },
              {
                id: "S11_4_2",
                label: this.translateService.instant('menu.entities.parameter'),
                disabled: !this.hasFullRights() && !this.hasAccess('KESZLET_PARAM'),
                routerLink: ['/parameter'],
                queryParams: {'paramCategory': 'KESZLET'},
                routerLinkActiveOptions: { exact: true },
              },
            ],
          },
          {
            id: "M11_5",
            label: this.translateService.instant('menu.entities.other'),
            disabled: !this.hasFullRights() && !this.hasAccess('MENU_T_EGYEB'),
            items: [
              {
                id: "S11_5_0",
                label: this.translateService.instant('menu.entities.pieceOfNews'),
                disabled: !this.hasFullRights() && !this.hasAccess('HIREK'),
                routerLink: ['/piece-of-news'],
                routerLinkActiveOptions: { exact: true },
              },
              {
                id: "S11_5_1",
                label: this.translateService.instant('menu.entities.parameter'),
                disabled: !this.hasFullRights() && !this.hasAccess('PARAM'),
                routerLink: ['/parameter'],
                queryParams: {'paramCategory': 'EGYEB'},
                routerLinkActiveOptions: { exact: true },
              },
              {
                id: "S11_5_2",
                label: this.translateService.instant('menu.entities.specialDay'),
                visible: this.accountService.hasAnyAuthority('ROLE_ADMIN'),
                disabled: !this.hasFullRights() && !this.hasAccess('KULNAPOK'),
                routerLink: ['/special-day'],
                routerLinkActiveOptions: { exact: true },
              },
              // {
              //   label: this.translateService.instant('menu.entities.commonVocCateg'),
              //   disabled: !this.hasFullRights() && !this.hasAccess('VSZOTKAT'),
              //   routerLink: ['/common-voc-categ'],
              //   routerLinkActiveOptions: { exact: true },
              //   id: "VSZOTKAT",
              // },
              {
                id: "S11_5_3",
                label: this.translateService.instant('menu.entities.commonVocabulary'),
                disabled: !this.hasFullRights() && !this.hasAccess('VSZOT'),
                routerLink: ['/common-voc-categ'],
                routerLinkActiveOptions: { exact: true },
              },
              {
                id: "S11_5_4",
                label: this.translateService.instant('menu.entities.country'),
                visible: this.accountService.hasAnyAuthority('ROLE_ADMIN'),
                disabled: !this.hasFullRights() && !this.hasAccess('ORSZAG'),
                routerLink: ['/country'],
                routerLinkActiveOptions: { exact: true },
              },
              {
                id: "S11_5_5",
                label: this.translateService.instant('menu.entities.emailTemplate'),
                disabled: !this.hasFullRights() && !this.hasAccess('EMAILSABLON'),
                routerLink: ['/email-template'],
                routerLinkActiveOptions: { exact: true },
              },
              {
                id: "S11_5_6",
                label: this.translateService.instant('menu.entities.assetInventoryArea'),
                disabled: !this.hasFullRights() && !this.hasAccess('LELTKOR'),
                routerLink: ['/asset-inventory-area'],
                routerLinkActiveOptions: { exact: true },
              },
              {
                id: "S11_5_7",
                label: this.translateService.instant('menu.entities.fileElement'),
                disabled: !this.hasFullRights() && !this.hasAccess('FAJLTIP'),
                routerLink: ['/file-element'],
                routerLinkActiveOptions: { exact: true },
              },
              {
                id: "S11_5_8",
                label: this.translateService.instant('menu.entities.fileDefinition'),
                disabled: !this.hasFullRights() && !this.hasAccess('FAJLDEF'),
                routerLink: ['/file-definition'],
                routerLinkActiveOptions: { exact: true },
              },
              {
                id: "S11_5_9",
                label: this.translateService.instant('menu.entities.documentComment'),
                disabled: !this.hasFullRights() && !this.hasAccess('DOKMEGJ'),
                routerLink: ['/document-comment'],
                routerLinkActiveOptions: { exact: true },
              },
            ],
          },
          {
            id: "M11_6",
            label: this.translateService.instant('menu.entities.access'),
            disabled: !this.hasFullRights() && !this.hasAccess('MENU_T_JOG'),
            items: [
              {
                id: "S11_6_0",
                label: this.translateService.instant('menu.entities.accessGroup'),
                disabled: !this.hasFullRights() && !this.hasAccess('JOGCSOP'),
                routerLink: ['/access-group'],
                routerLinkActiveOptions: { exact: true },
              },
              {
                id: "S11_6_1",
                label: this.translateService.instant('menu.entities.accessRight'),
                disabled: !this.hasFullRights() && !this.hasAccess('JOGTAB'),
                routerLink: ['/access-right'],
                routerLinkActiveOptions: { exact: true },
              },
            ],
          },
        ],
      },
      {
        id: "M12",
        label: this.translateService.instant('menu.admin.main'),
        visible: this.accountService.hasAnyAuthority('ROLE_ADMIN'),
        icon: 'pi pi-fw pi-cog',
        routerLinkActiveOptions: { exact: true },
        items: [
          {
            id: "S12_0",
            label: this.translateService.instant('menu.admin.userManagement'),
            icon: 'pi pi-fw pi-user',
            routerLink: ['/admin/user-management'],
          },
          {
            id: "S12_1",
            label: this.translateService.instant('menu.entities.application'),
            icon: 'pi pi-fw pi-sitemap',
            routerLink: ['/application'],
            routerLinkActiveOptions: { exact: true },
          },
          {
            id: "S12_2",
            label: this.translateService.instant('menu.entities.magJsonParam'),
            icon: 'pi pi-fw pi-palette',
            routerLink: ['/mag-json-param'],
            routerLinkActiveOptions: { exact: true },
          },
          {
            id: "M12_0",
            label: this.translateService.instant('menu.entities.access'),
            items: [
              {
                id: "S12_0_0",
                label: this.translateService.instant('menu.entities.accessFunction'),
                routerLink: ['/access-function'],
                routerLinkActiveOptions: { exact: true },
              },
            ],
          },
          { separator: true },
          { 
            id: "S12_3",
            label: this.translateService.instant('menu.admin.metrics'),
            icon: 'pi pi-fw pi-chart-bar',
            routerLink: ['/admin/metrics']
          },
          { 
            id: "S12_4",
            label: this.translateService.instant('menu.admin.health'),
            icon: 'pi pi-fw pi-heart',
            routerLink: ['/admin/health']
          },
          {
            id: "S12_5",
            label: this.translateService.instant('menu.admin.configuration'),
            icon: 'pi pi-fw pi-cog',
            routerLink: ['/admin/configuration'],
          },
          { 
            id: "S12_6",
            label: this.translateService.instant('menu.admin.logs'),
            icon: 'pi pi-fw pi-copy',
            routerLink: ['/admin/logs']
          },
          {
            id: "S12_7",
            label: this.translateService.instant('menu.admin.apidocs'),
            icon: 'pi pi-fw pi-book',
            routerLink: ['/admin/docs'],
            visible: this.openAPIEnabled,
          },
        ],
      },
      {
        id: "M13",
        label: this.translateService.instant('menu.language'),
        visible: this.languages.length > 1,
        icon: 'pi pi-fw pi-flag',
        items: [
          {
            id: "C13_0",
            label: 'English',
            command: () => {this.changeLanguage('en');},
          },
          {
            id: "C13_1",
            label: 'Magyar',
            command: () => {this.changeLanguage('hu');},
          },
        ],
      },
      {
        id: "M14",
        label: this.translateService.instant('menu.account.main'),
        icon: 'pi pi-fw pi-user',
        routerLinkActiveOptions: { exact: true },
        items: [
          {
            id: "S14_0",
            label: this.translateService.instant('menu.account.settings'),
            icon: 'pi pi-fw pi-cog',
            routerLink: ['/account/settings'],
            visible: this.isAuthenticated(),
          },
          {
            id: "S14_1",
            label: this.translateService.instant('menu.account.password'),
            icon: 'pi pi-fw pi-lock',
            routerLink: ['/account/password'],
            visible: this.isAuthenticated(),
          },
          {
            id: "C14_2",
            label: this.translateService.instant('menu.account.logout'),
            icon: 'pi pi-fw pi-sign-out',
            command: () => {this.logout();},
            visible: this.isAuthenticated(),
          },
          {
            id: "C14_3",
            label: this.translateService.instant('menu.account.login'),
            icon: 'pi pi-fw pi-sign-in',
            command: () => {this.login();},
            visible: !this.isAuthenticated(),
          },
          {
            id: "S14_4",
            label: this.translateService.instant('menu.account.register'),
            icon: 'pi pi-fw pi-user-plus',
            routerLink: ['/account/register'],
            visible: !this.isAuthenticated(),
          },
        ],
      },
    ];
    this.fixupItems(this.items);

    if (this.activeItem) {
      this.goToMenuItem(this.activeItem,true)
    }
  return of(undefined);
  }

  changeLanguage(languageKey: string): void {
    this.sessionStorage.store('locale', languageKey);
    this.subs.push(this.translateService.use(languageKey).subscribe(() => {
      this.subs.push(this.translateService.get('primeng').subscribe(res => {
        this.config.setTranslation(res)
        this.dataSharingService.displayMenu.next(false);
      }));
    }));
  }

  isAuthenticated(): boolean {
    return this.accountService.isAuthenticated();
  }

  isApplicationSelected(): boolean {
    return this.globalsService.getParam("AppId") !== '';
  }

  hasFullRights(): boolean {
    return this.globalsService.getParam("FullRights").toString() === "true";
  }

  isCommerce(): boolean {
    return this.globalsService.getParam("CommerceFlag").toString() === "true";
  }

  isIntrastat(): boolean {
    return this.globalsService.getParam("IntrastatExportFlag").toString() === "true" || this.globalsService.getParam("IntrastatImportFlag").toString() === "true";
  }

  hasAccess( functionCode: string, listenToAdmin: boolean = true): boolean {
    return (listenToAdmin && this.accountService.hasAnyAuthority('ROLE_ADMIN')) || this.globalsService.getAccessRightsByFunction(functionCode).length > 0;
  }

  login(): void {
    // this.router.navigate(['/login']);
    // this.loginModalService.open();
    this.dialogContainer.createComponent<LoginComponent>(LoginComponent);
  }

  logout(): void {
    this.loginService.logout();
    this.router.navigate(['']);
    this.activeItem = undefined;
    this.previousItem = undefined;
    this.dataSharingService.activeMenuItem.next('S0');
    this.dataSharingService.displayMenu.next(false);
    this.globalsService.setParam("AppId",'');
    this.globalsService.setParam("Messages",[]);
    this.globalsService.setParam("CommerceFlag",false);
    this.globalsService.setParam("Stores",[]);
    this.dataSharingService.appName.next('');
  }

  getImageUrl(): string {
    return this.isAuthenticated() ? this.accountService.getImageUrl() : '';
  }

  loadGlStatementGenerateDialog(): void{
    const componentRef = this.dialogContainer.createComponent<GlStatementGenerateComponent>(GlStatementGenerateComponent);
    componentRef.instance.deleteSelf.pipe(tap(() => componentRef.destroy())).subscribe();
  }

  loadGlStatementLoadOpeningDialog(): void{
    const componentRef = this.dialogContainer.createComponent<GlStatementLoadOpeningComponent>(GlStatementLoadOpeningComponent);
    componentRef.instance.deleteSelf.pipe(tap(() => componentRef.destroy())).subscribe();
  }

  loadGlStatementCloseYearDialog(): void{
    const componentRef = this.dialogContainer.createComponent<GlStatementCloseYearComponent>(GlStatementCloseYearComponent);
    componentRef.instance.deleteSelf.pipe(tap(() => componentRef.destroy())).subscribe();
  }

  loadIntrastatCloseMonthDialog(): void{
    const componentRef = this.dialogContainer.createComponent<IntrastatCloseMonthComponent>(IntrastatCloseMonthComponent);
    componentRef.instance.deleteSelf.pipe(tap(() => componentRef.destroy())).subscribe();
  }

  loadAccountsBalanceArrangeDialog(): void{
    const componentRef = this.dialogContainer.createComponent<AccountsBalanceArrangeComponent>(AccountsBalanceArrangeComponent);
    componentRef.instance.deleteSelf.pipe(tap(() => componentRef.destroy())).subscribe();
  }

  goToMenuItem( id: string, build?: boolean): void{
// console.log(`goToMenuItem (id: ${id}, build: ${build})`);
    let items = this.items;
// console.log(items);

    if (build && items.length > 0) {
      const count = (id.match(/_/g) || []).length;
      let item: MenuItem[];
      let index = -1;
  
      if (count > 0) {
        for (let i = 0; i < count; i++) {
          index = id.indexOf("_",++index);
          const parentId = 'M' + id.substring(1,index);
          item = items.filter(mi => (mi.id === parentId));
          item[0].expanded = true;
          items = item[0].items!; 
        }
      }
    }
    this.activeItem = id;
    if (items.length > 0) {
      this.selectMenuItem();
    }  
  }

  refreshMenu(): void {
    this.dataSharingService.displayMenu.next(false);
    setTimeout(() => this.dataSharingService.displayMenu.next(true),0);
  }
}
