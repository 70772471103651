import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as dayjs from 'dayjs';

import { isPresent } from 'app/core/util/operators';
import { DATE_FORMAT } from 'app/config/input.constants';
import { ApplicationConfigService } from 'app/core/config/application-config.service';
import { createRequestOption } from 'app/core/request/request-util';
import { IInvoiceIn, IInvoiceInBalance, getInvoiceInIdentifier } from '../invoice-in.model';
import { GlobalsService } from 'app/shared/globals/globals.service';
import { IAdvancePosition } from 'app/entities/invoice-in/invoice-in.model';
import { IBackEndMessage } from 'app/shared/interfaces/back-end-message/back-end-message.model';
import { IInvoiceInView } from '../invoice-in-view.model';
import { ICommercialInvoiceInParam } from 'app/shared/interfaces/commercial-invoice-in-param/commercial-invoice-in-param.model';
import { IInvoiceInLine } from '../invoice-in-lines/invoice-in-line.model';

export type EntityResponseType = HttpResponse<IInvoiceIn>;
export type EntityArrayResponseType = HttpResponse<IInvoiceIn[]>;
export type EntityArrayResponseTypeView = HttpResponse<IInvoiceInView[]>;

@Injectable({ providedIn: 'root' })
export class InvoiceInService {
  protected resourceUrl = this.applicationConfigService.getEndpointFor('api/invoice-ins');

  constructor(
    protected http: HttpClient,
    protected applicationConfigService: ApplicationConfigService,
    protected globalsService: GlobalsService
  ) {}

  create(invoiceIn: IInvoiceIn): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(invoiceIn);
    return this.http
      .post<IInvoiceIn>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  update(invoiceIn: IInvoiceIn): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(invoiceIn);
    return this.http
      .put<IInvoiceIn>(`${this.resourceUrl}/${this.globalsService.getParam('AppId')}&${getInvoiceInIdentifier(invoiceIn) as number}`, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  partialUpdate(invoiceIn: IInvoiceIn): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(invoiceIn);
    return this.http
      .patch<IInvoiceIn>(`${this.resourceUrl}/${getInvoiceInIdentifier(invoiceIn) as number}`, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<IInvoiceIn>(`${this.resourceUrl}/withDetails/${this.globalsService.getParam('AppId')}&${id}`, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  queryViews(req?: any): Observable<EntityArrayResponseTypeView> {
    const options = createRequestOption(req);
    return this.http
      .get<IInvoiceInView[]>(`${this.resourceUrl}?appId.equals=${this.globalsService.getParam("AppId")}`, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseTypeView) => this.convertDateArrayFromServer(res)));
  }

  queryViewsForNav(partnerId: number, req?: any): Observable<EntityArrayResponseTypeView> {
    const options = createRequestOption(req);
    return this.http
      .get<IInvoiceInView[]>(`${this.resourceUrl}/nav-pairing/${this.globalsService.getParam("AppId")}&${partnerId}`, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseTypeView) => this.convertDateArrayFromServer(res)));
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<IInvoiceIn[]>(`${this.resourceUrl}/invoice-ins?appId.equals=${this.globalsService.getParam("AppId")}`, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  findBalance(id: number): Observable<HttpResponse<IInvoiceInBalance>> {
    return this.http
      .get<IInvoiceInBalance>(`/api/invoice-in-balance-views/${id}`, { observe: 'response' })
      .pipe(map((res: HttpResponse<IInvoiceInBalance>) => this.convertBalanceDateFromServer(res)));
  }

  queryBalance(req?: any): Observable<HttpResponse<IInvoiceInBalance[]>> {
    const options = createRequestOption(req);
    return this.http
      .get<IInvoiceInBalance[]>('/api/invoice-in-balance-views', { params: options, observe: 'response' })
      .pipe(map((res: HttpResponse<IInvoiceInBalance[]>) => this.convertBalanceDateArrayFromServer(res)));
  }

  queryAdvancePositions(req?: any): Observable<HttpResponse<IAdvancePosition[]>> {
    const options = createRequestOption(req);
    return this.http
      .get<IAdvancePosition[]>('/api/advance-positions-in', { params: options, observe: 'response' })
      .pipe(map((res: HttpResponse<IAdvancePosition[]>) => this.convertAdvanceDateArrayFromServer(res)));
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${this.globalsService.getParam('AppId')}&${id}`, { observe: 'response' });
  }

  postToGl(id: number): Observable<IBackEndMessage[]> {
    return this.http.put<IBackEndMessage[]>(`${this.resourceUrl}/glpost/${this.globalsService.getParam('AppId')}&${id}`, { observe: 'response' });
  }

  revokeFromGl(id: number): Observable<IBackEndMessage[]> {
    return this.http.put<IBackEndMessage[]>(`${this.resourceUrl}/glrevoke/${this.globalsService.getParam('AppId')}&${id}`, { observe: 'response' });
  }
  
  genBankTransferOrder(invoiceId: number): Observable<IBackEndMessage[]> {
    return this.http.put<IBackEndMessage[]>(`api/bank-transfer-orders/gen-from-invoice/${this.globalsService.getParam('AppId')}&${invoiceId}`, { observe: 'response' });
  }

  commercialLinesLoad(param: ICommercialInvoiceInParam): Observable<HttpResponse<IInvoiceInLine[]>> {
    return this.http
      .put<IInvoiceInLine[]>(`${this.resourceUrl}/commercial-lines-load/${this.globalsService.getParam('AppId')}`, param, { observe: 'response' });
  }

  arrangeAccountsBalance(accountType?: string): Observable<IBackEndMessage[]> {
    return this.http
      .post<IBackEndMessage[]>(`api/accounts_balance/arrange/${this.globalsService.getParam('AppId')}&${accountType}`, { observe: 'response' });
  }

  addInvoiceInToCollectionIfMissing(
    invoiceInCollection: IInvoiceIn[],
    ...invoiceInsToCheck: (IInvoiceIn | null | undefined)[]
  ): IInvoiceIn[] {
    const invoiceIns: IInvoiceIn[] = invoiceInsToCheck.filter(isPresent);
    if (invoiceIns.length > 0) {
      const invoiceInCollectionIdentifiers = invoiceInCollection.map(invoiceInItem => getInvoiceInIdentifier(invoiceInItem)!);
      const invoiceInsToAdd = invoiceIns.filter(invoiceInItem => {
        const invoiceInIdentifier = getInvoiceInIdentifier(invoiceInItem);
        if (invoiceInIdentifier == null || invoiceInCollectionIdentifiers.includes(invoiceInIdentifier)) {
          return false;
        }
        invoiceInCollectionIdentifiers.push(invoiceInIdentifier);
        return true;
      });
      return [...invoiceInsToAdd, ...invoiceInCollection];
    }
    return invoiceInCollection;
  }

  protected convertDateFromClient(invoiceIn: IInvoiceIn): IInvoiceIn {
    const invoiceInLines: Array<{}> = [];
    invoiceIn.invoiceInLines?.forEach(invoiceInLine => {
      invoiceInLines.push(Object.assign({}, invoiceInLine, {
        advancePaymentDate: invoiceInLine.advancePaymentDate?.isValid() ? invoiceInLine.advancePaymentDate.format(DATE_FORMAT) : undefined,
        lineDeliveryDate: invoiceInLine.lineDeliveryDate?.isValid() ? invoiceInLine.lineDeliveryDate.format(DATE_FORMAT) : undefined,
        newTransportMeanFirstEntry: invoiceInLine.newTransportMeanFirstEntry?.isValid() ? invoiceInLine.newTransportMeanFirstEntry.format(DATE_FORMAT) : undefined,
        dieselPurchaseDate: invoiceInLine.dieselPurchaseDate?.isValid() ? invoiceInLine.dieselPurchaseDate.format(DATE_FORMAT) : undefined,
      }))
    })

    const docFiles: Array<{}> = [];
    invoiceIn.docFiles?.forEach(docFile => {
      docFiles.push(Object.assign({}, docFile, {
        uploadTime: docFile.uploadTime?.isValid() ? docFile.uploadTime.toJSON() : undefined,
      }))
    })

    return Object.assign({}, invoiceIn, {
      invoiceIssueDate: invoiceIn.invoiceIssueDate?.isValid() ? invoiceIn.invoiceIssueDate.format(DATE_FORMAT) : undefined,
      deliveryDate: invoiceIn.deliveryDate?.isValid() ? invoiceIn.deliveryDate.format(DATE_FORMAT) : undefined,
      deliveryPeriodStart: invoiceIn.deliveryPeriodStart?.isValid() ? invoiceIn.deliveryPeriodStart.format(DATE_FORMAT) : undefined,
      deliveryPeriodEnd: invoiceIn.deliveryPeriodEnd?.isValid() ? invoiceIn.deliveryPeriodEnd.format(DATE_FORMAT) : undefined,
      accountingDeliveryDate: invoiceIn.accountingDeliveryDate?.isValid()
        ? invoiceIn.accountingDeliveryDate.format(DATE_FORMAT)
        : undefined,
      paymentDate: invoiceIn.paymentDate?.isValid() ? invoiceIn.paymentDate.format(DATE_FORMAT) : undefined,
      pfEvidenceDocumentDate: invoiceIn.pfEvidenceDocumentDate?.isValid()
        ? invoiceIn.pfEvidenceDocumentDate.format(DATE_FORMAT)
        : undefined,
      createdDate: invoiceIn.createdDate?.isValid() ? invoiceIn.createdDate.toJSON() : undefined,
      lastModifiedDate: invoiceIn.lastModifiedDate?.isValid() ? invoiceIn.lastModifiedDate.toJSON() : undefined,
      invoiceInLines: invoiceIn.invoiceInLines ? invoiceInLines : undefined,
      docFiles: invoiceIn.docFiles ? docFiles : undefined,
    });
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.invoiceIssueDate = res.body.invoiceIssueDate ? dayjs(res.body.invoiceIssueDate) : undefined;
      res.body.deliveryDate = res.body.deliveryDate ? dayjs(res.body.deliveryDate) : undefined;
      res.body.deliveryPeriodStart = res.body.deliveryPeriodStart ? dayjs(res.body.deliveryPeriodStart) : undefined;
      res.body.deliveryPeriodEnd = res.body.deliveryPeriodEnd ? dayjs(res.body.deliveryPeriodEnd) : undefined;
      res.body.accountingDeliveryDate = res.body.accountingDeliveryDate ? dayjs(res.body.accountingDeliveryDate) : undefined;
      res.body.paymentDate = res.body.paymentDate ? dayjs(res.body.paymentDate) : undefined;
      res.body.pfEvidenceDocumentDate = res.body.pfEvidenceDocumentDate ? dayjs(res.body.pfEvidenceDocumentDate) : undefined;
      res.body.createdDate = res.body.createdDate ? dayjs(res.body.createdDate) : undefined;
      res.body.lastModifiedDate = res.body.lastModifiedDate ? dayjs(res.body.lastModifiedDate) : undefined;
      res.body.invoiceInLines?.forEach(invoiceInLine => {
        invoiceInLine.advancePaymentDate = invoiceInLine.advancePaymentDate ? dayjs(invoiceInLine.advancePaymentDate) : undefined;
        invoiceInLine.lineDeliveryDate = invoiceInLine.lineDeliveryDate ? dayjs(invoiceInLine.lineDeliveryDate) : undefined;
        invoiceInLine.newTransportMeanFirstEntry = invoiceInLine.newTransportMeanFirstEntry ? dayjs(invoiceInLine.newTransportMeanFirstEntry) : undefined;
        invoiceInLine.dieselPurchaseDate = invoiceInLine.dieselPurchaseDate ? dayjs(invoiceInLine.dieselPurchaseDate) : undefined;
        invoiceInLine.createdDate = invoiceInLine.createdDate ? dayjs(invoiceInLine.createdDate) : undefined;
        invoiceInLine.lastModifiedDate = invoiceInLine.lastModifiedDate ? dayjs(invoiceInLine.lastModifiedDate) : undefined;
      });
      res.body.docFiles?.forEach(docFile => {
        docFile.createdDate = docFile.createdDate ? dayjs(docFile.createdDate) : undefined;
        docFile.lastModifiedDate = docFile.lastModifiedDate ? dayjs(docFile.lastModifiedDate) : undefined;
        docFile.uploadTime = docFile.uploadTime ? dayjs(docFile.uploadTime) : undefined;
      });
    }
    return res;
  }

  protected convertDateArrayFromServer(res: EntityArrayResponseType | EntityArrayResponseTypeView): EntityArrayResponseType | EntityArrayResponseTypeView {
    if (res.body) {
      res.body.forEach((invoiceIn: IInvoiceInView) => {
        invoiceIn.invoiceIssueDate = invoiceIn.invoiceIssueDate ? dayjs(invoiceIn.invoiceIssueDate) : undefined;
        invoiceIn.deliveryDate = invoiceIn.deliveryDate ? dayjs(invoiceIn.deliveryDate) : undefined;
        invoiceIn.deliveryPeriodStart = invoiceIn.deliveryPeriodStart ? dayjs(invoiceIn.deliveryPeriodStart) : undefined;
        invoiceIn.deliveryPeriodEnd = invoiceIn.deliveryPeriodEnd ? dayjs(invoiceIn.deliveryPeriodEnd) : undefined;
        invoiceIn.accountingDeliveryDate = invoiceIn.accountingDeliveryDate ? dayjs(invoiceIn.accountingDeliveryDate) : undefined;
        invoiceIn.paymentDate = invoiceIn.paymentDate ? dayjs(invoiceIn.paymentDate) : undefined;
        // invoiceIn.pfEvidenceDocumentDate = invoiceIn.pfEvidenceDocumentDate ? dayjs(invoiceIn.pfEvidenceDocumentDate) : undefined;
        invoiceIn.createdDate = invoiceIn.createdDate ? dayjs(invoiceIn.createdDate) : undefined;
        invoiceIn.lastModifiedDate = invoiceIn.lastModifiedDate ? dayjs(invoiceIn.lastModifiedDate) : undefined;
      });
    }
    return res;
  }

  protected convertBalanceDateFromServer(res: HttpResponse<IInvoiceInBalance>): HttpResponse<IInvoiceInBalance> {
    if (res.body) {
      res.body.invoiceIssueDate = res.body.invoiceIssueDate ? dayjs(res.body.invoiceIssueDate) : undefined;
      res.body.paymentDate = res.body.paymentDate ? dayjs(res.body.paymentDate) : undefined;
    }
    return res;
  }

  protected convertBalanceDateArrayFromServer(res: HttpResponse<IInvoiceInBalance[]>): HttpResponse<IInvoiceInBalance[]> {
    if (res.body) {
      res.body.forEach((invoiceIn: IInvoiceInBalance) => {
        invoiceIn.invoiceIssueDate = invoiceIn.invoiceIssueDate ? dayjs(invoiceIn.invoiceIssueDate) : undefined;
        invoiceIn.paymentDate = invoiceIn.paymentDate ? dayjs(invoiceIn.paymentDate) : undefined;
      });
    }
    return res;
  }

  protected convertAdvanceDateArrayFromServer(res: HttpResponse<IAdvancePosition[]>): HttpResponse<IAdvancePosition[]> {
    if (res.body) {
      res.body.forEach((advancePosition: IAdvancePosition) => {
        advancePosition.invoiceIssueDate = advancePosition.invoiceIssueDate ? dayjs(advancePosition.invoiceIssueDate) : undefined;
        advancePosition.deliveryDate = advancePosition.deliveryDate ? dayjs(advancePosition.deliveryDate) : undefined;
      });
    }
    return res;
  } 
}
