<div [class]="displayMenu ? 'body-with-menu' : 'body-without-menu'">
  <auth-page-ribbon></auth-page-ribbon>

  <auth-galleria-full
    [displayGalleria]="visibleGalleria"
    [images]="galleriaImages"
  ></auth-galleria-full>
  
  <i *ngIf="!displayMenu" class="pi pi-bars" (click)="dataSharingService.displayMenu.next(true)" style="padding: 10px 10px; cursor: pointer; font-size: 1.5rem;"></i>

  <!-- <div *ngIf="displayMenu" style="display:inline-block">
    <router-outlet name="navbar"></router-outlet>
  </div> -->

  <p-sidebar
   [(visible)]="displayMenu"
   position="left"
   [style]="{'width':'14%'}"
   [modal]="false"
   [dismissible]="false"
   [closeOnEscape]="false"
   [showCloseIcon]="false"
  >
    <!-- <ng-template pTemplate="closeicon">
      <i class="pi pi-arrow-left"></i>
    </ng-template> -->
    <ng-template pTemplate="content" style="padding: 0">
      <auth-navbar></auth-navbar>
    </ng-template>
    <ng-template pTemplate="footer">
      <i class="pi pi-arrow-left" (click)="dataSharingService.displayMenu.next(false)" style="cursor: pointer; font-size: 1.5rem;"></i>
    </ng-template>
  </p-sidebar>

  <div class="container-fluid">
    <div class="card jh-card">
      <router-outlet></router-outlet>
      <router-outlet name="dialog"></router-outlet>
    </div>

    <auth-footer></auth-footer>

    <p-sidebar
      [(visible)]="visibleSidebar"
      position="bottom"
      [modal]="false"
      [styleClass]="alerts > 1 ? 'p-sidebar-md' : 'p-sidebar-sm'"
      (onHide)="hideSidebar()"
    >
      <auth-alert></auth-alert>
      <auth-alert-error></auth-alert-error>
      
    </p-sidebar>

    <p-dialog [header]="'magusCooking.title' | translate" [modal]="true" [(visible)]="visibleWorking" appendTo="body" styleClass="disable-scroll" [closable]="true">
      <div class="row justify-content-center">
        <div style="display: flex; align-items: center;">
          <img style="max-height: 80%; margin-right: 20px;" src="../../../content/images/magus_cooks.png">
          <div style="font-size: medium; font-weight: 500;">
              <span authTranslate="magusCooking.message"> 
                Megvárhatod, hogy mit főz ki, vagy bezárhatod az ablakot és folytathatod valami mással. <br>
                Az eredményről értesülni fogsz, nem maradsz le semmiről...
              </span>
          </div>
        </div>
      </div>
    </p-dialog>
  </div>
</div>