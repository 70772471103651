import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DATE_FORMAT } from 'app/config/input.constants';
import { IBackEndMessage } from 'app/shared/interfaces/back-end-message/back-end-message.model';
import { YearMonthService } from 'app/shared/services/year-month.service';
import * as dayjs from 'dayjs';
import { ConfirmationService } from 'primeng/api';
import { GlStatementService } from '../../service/gl-statement.service';
import { GlobalsService } from '../../../../shared/globals/globals.service';
import { AlertService } from 'app/core/util/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { DataSharingService } from 'app/shared/services/data-sharing.service';

@Component({
  selector: 'auth-gl-statement-generate',
  templateUrl: './gl-statement-generate.component.html'
})
export class GlStatementGenerateComponent implements OnInit {
  @Output() deleteSelf: EventEmitter<void> = new EventEmitter<void>();
  currentGlYear?: number;
  newGlYear?: number;

  constructor(
    protected globalsService: GlobalsService,
    protected yearMonthService: YearMonthService,
    protected confirmationService: ConfirmationService,
    protected glStatementService: GlStatementService,
    protected translateService: TranslateService,
    private dataSharingService: DataSharingService,
    private alertService: AlertService
    ) { }

  ngOnInit(): void {
    this.getGlYear();
  }

  getGlYear(): void{
    this.yearMonthService.getYearMonth("FOKONYV").subscribe(resYearMonth => {
      const l_yearMonth = resYearMonth.body ?? undefined;
      if (l_yearMonth) {
        this.currentGlYear = Math.trunc(l_yearMonth.yearMonth! / 100);
        this.newGlYear = this.currentGlYear + 1;
        this.showConfirmDialog();
      }
    })
  }

  showConfirmDialog(): void{
    this.confirmationService.confirm({
      message: this.translateService.instant('magusApp.glStatement.generate.dialogQuestion', {
        newGlYear: this.newGlYear,
        currentGlYear: this.currentGlYear,
      }),
      icon: 'pi pi-exclamation-triangle',
      acceptButtonStyleClass: 'btn btn-primary',
      rejectButtonStyleClass: 'btn btn-secondary',
      accept: () => {
        this.generateGlStatement();
        this.deleteSelf.emit();
      },
      reject: () => {
        this.deleteSelf.emit();
      },
    });
  }

  generateGlStatement(): void{
    this.dataSharingService.visibleWorking.next(true);
    this.glStatementService.generateFromPreviousYear(this.newGlYear!).subscribe((res: IBackEndMessage[]) => {
      this.dataSharingService.visibleWorking.next(false);
      if (res.length) {
        this.showMessages(res);
      }
    });
  }

  showMessages(messages: IBackEndMessage[]): void {
    if(messages.length){
      const mp: {[key: string]: any} = {};
      messages.forEach( m => {
        if (m.messageParam) {
          const keys = Object.keys(m.messageParam);
          const values = Object.values(m.messageParam);
          keys.forEach((k,i) => {
            const v = values[i];
            mp[k] = k.indexOf("date_") < 0 ? v : dayjs(v,DATE_FORMAT).format(this.globalsService.getParam('DateFormat'));
          });
        }
          
        this.alertService.addAlert({
          type: m.messageType === "WARN" ? 'warning' : m.messageType === "ERROR" ? 'danger' : m.messageType === "INFO" ? 'info' : 'success',
          message: this.translateService.instant( "magusApp.backEndMessage."+ m.messageCode!, mp),
          timeout: 10000
        });
      });  
    }
  }
}
