import { Injectable, Output, EventEmitter } from '@angular/core';
import { IApplication } from 'app/entities/application/application.model';
import { ApplicationService } from 'app/entities/application/service/application.service';

import { AccountService } from 'app/core/auth/account.service';
import { ITable } from 'app/core/table/table.model';
import { IUser } from '../entities/user/user.model';

import { AdminUserService } from 'app/entities/adminuser/adminuser.service';

import { HttpResponse } from '@angular/common/http';
import { IMagJsonParam } from 'app/entities/mag-json-param/mag-json-param.model';
import { MagJsonParamService } from 'app/entities/mag-json-param/service/mag-json-param.service';
import { GlobalsService } from 'app/shared/globals/globals.service';
import { DomSanitizer } from '@angular/platform-browser';
import { DataSharingService } from 'app/shared/services/data-sharing.service';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  @Output() getLoggedInName: EventEmitter<any> = new EventEmitter();
  public apps?: IApplication[];
  public user?: IUser;
  public appID: string;
  public appsNum: number;
  public table?: ITable;
  param?: IMagJsonParam;

  constructor(
    private sanitizer: DomSanitizer,
    protected applicationService: ApplicationService,
    protected accountService: AccountService,
    protected magJsonParamService: MagJsonParamService,
    protected adminUserService: AdminUserService,
    protected globalsService: GlobalsService,
    protected dataSharingService: DataSharingService,
  ) {
    this.appID = '';
    this.appsNum = 0;
  }

  public setApps(userLogin: string): void {
    if (userLogin) {
      // this.setUser(userLogin);
      const sub = this.applicationService.queryByUser(userLogin).subscribe(
        (res: HttpResponse<IApplication[]>) => this.onSuccess(res.body)
      );
    } else {
      this.apps = [];
      this.appID = '';
      this.appsNum = 0;
    }
  }

  public loadDateFormats(userLogin: string): void {
    this.magJsonParamService.findByUserAndEntity(userLogin, 'dateFormats').subscribe((res: HttpResponse<IMagJsonParam>) => {
      setTimeout(() => {
        this.param = res.body ?? undefined;

        if (this.param) {
          const jsonContent = JSON.parse(this.param.jsonContent!);

          this.globalsService.setParam("DateFormat", jsonContent.DateFormat);
          this.globalsService.setParam("DatetimeFormat", jsonContent.DatetimeFormat);
          this.globalsService.setParam("TimeFormat", jsonContent.TimeFormat);
          this.globalsService.setParam("TimestampFormat", jsonContent.TimestampFormat);
          this.globalsService.setParam("YearMonthFormat", jsonContent.YearMonthFormat);
        }
      }, 500);
    });
  }

  public workingMessageOn(): void {
    this.dataSharingService.visibleWorking.next(true);
  }

  public workingMessageOff(): void {
    this.dataSharingService.visibleWorking.next(false);
  }

  protected onSuccess(data: IApplication[] | null): void {
    this.apps = data ?? [];
    this.appsNum = this.apps.length;

    this.apps.forEach( app => {
      if (app.logo) {
        app.safeUrl = this.sanitizer.bypassSecurityTrustUrl(`data:image/jpg;base64,${app.logo}`);
      }
    })
    if (this.appID === '') {
      this.appID = this.apps[0]?.id ?? '';
    }
  }

  protected onError(errorMessage: string): void {
    // eslint-disable-next-line no-console
    console.log(errorMessage);
  }

  protected setUser(login: string): void {
    this.adminUserService.find(login).subscribe(res => {
      const adminUser = res.body;
      // // eslint-disable-next-line no-console
      // console.log(adminUser);
      this.user = { id: adminUser?.id, login: adminUser?.login };
    });
  }
}
