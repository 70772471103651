<div class="loading-button-container">
  <button
    [type]="type"
    [disabled]="isDisabled || isLoading"
    [class]="class"
    (click)="handleClick($event)"
  >
    <i [class]="iconClass" aria-hidden="true"></i>
    <span [authTranslate]="label">Load lines / Whatever</span>
  </button>
  <div *ngIf="isLoading" class="loading-spinner">
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
  </div>
</div>