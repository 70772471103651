import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { GlobalsService } from 'app/shared/globals/globals.service';

@Injectable()
export class DataSharingService {
    public appName: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public visibleSidebar: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public visibleGalleria: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public nofAlerts: BehaviorSubject<number> = new BehaviorSubject<number>(this.globalsService.getParam("Messages").length);
    public galleriaImages: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    public activeMenuItem: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public displayMenu: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public visibleWorking: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    
    constructor(
        protected globalsService: GlobalsService,
      ) {}
}