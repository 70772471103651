import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DATE_FORMAT } from 'app/config/input.constants';
import { IBackEndMessage } from 'app/shared/interfaces/back-end-message/back-end-message.model';
import * as dayjs from 'dayjs';
import { ConfirmationService } from 'primeng/api';
import { AlertService } from 'app/core/util/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { ParameterService } from 'app/entities/parameter/service/parameter.service';
import { GlobalsService } from 'app/shared/globals/globals.service';
import { InvoiceInService } from '../service/invoice-in.service';

@Component({
  selector: 'auth-accounts-balance-arrange',
  templateUrl: './accounts-balance-arrange.component.html'
})
export class AccountsBalanceArrangeComponent {
  @Output() deleteSelf: EventEmitter<void> = new EventEmitter<void>();
  isVisible = true;

  buyerFlag: boolean = true;
  supplierFlag: boolean = true;

  accountType = '';

  isLoading?: boolean = false;

  constructor(
    protected globalsService: GlobalsService,
    protected confirmationService: ConfirmationService,
    protected translateService: TranslateService,
    protected parameterService: ParameterService,
    private alertService: AlertService,
    protected invoiceInService: InvoiceInService
    ) {}

  // showConfirmDialog(): void{
  //   this.confirmationService.confirm({
  //     message: this.translateService.instant('magusApp.glStatement.closeYear.dialogQuestion', {
  //       yearToClose: this.yearToClose,
  //     }),
  //     icon: 'pi pi-exclamation-triangle',
  //     acceptButtonStyleClass: 'btn btn-primary',
  //     rejectButtonStyleClass: 'btn btn-secondary',
  //     accept: () => {
  //       this.closeGlYear();
  //       this.deleteSelf.emit();
  //     },
  //     reject: () => {
  //       this.deleteSelf.emit();
  //     },
  //   });
  // }

  determineAccountType(): void {
    if (this.buyerFlag && !this.supplierFlag) {
      this.accountType = 'VE';
    } else if (this.supplierFlag && !this.buyerFlag) {
      this.accountType = 'SZ';
    } else if (this.buyerFlag && this.supplierFlag) {
      this.accountType = 'O';
    }

    if(this.accountType){
      this.arrangeAccountsBalance();
    }
  }

  arrangeAccountsBalance(): void{
    this.isLoading = true;
    this.invoiceInService.arrangeAccountsBalance(this.accountType).subscribe((res: IBackEndMessage[]) => {
      if (res.length) {
        this.showMessages(res);
        this.previousState();
        this.isLoading = false;
      }
    });
  }

  previousState(): void {
    this.isVisible = false;
    this.deleteSelf.emit();
  }

  showMessages(messages: IBackEndMessage[]): void {
    if(messages.length){
      const mp: {[key: string]: any} = {};
      messages.forEach( m => {
        if (m.messageParam) {
          const keys = Object.keys(m.messageParam);
          const values = Object.values(m.messageParam);
          keys.forEach((k,i) => {
            const v = values[i];
            mp[k] = k.indexOf("date_") < 0 ? v : dayjs(v,DATE_FORMAT).format(this.globalsService.getParam('DateFormat'));
          });
        }

        let messageCodeToTranslate = m.messageCode;
        
        if(m.messageType === "SUCCESS"){
          if(this.accountType === 'VE'){
            messageCodeToTranslate = 'invoiceBalance.arrangeOKVE'
          } else if (this.accountType === 'SZ') {
            messageCodeToTranslate = 'invoiceBalance.arrangeOKSZ'
          } else if (this.accountType === 'O') {
            messageCodeToTranslate = 'invoiceBalance.arrangeOKO'
          }
        }

        this.alertService.addAlert({
          type: m.messageType === "WARN" ? 'warning' : m.messageType === "ERROR" ? 'danger' : m.messageType === "INFO" ? 'info' : 'success',
          message: this.translateService.instant( "magusApp.backEndMessage."+ messageCodeToTranslate, mp),
          timeout: 10000
        });
      });  
    }
  }
}
