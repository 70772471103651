import { NgModule } from '@angular/core';
import { SharedLibsModule } from './shared-libs.module';
import { FindLanguageFromKeyPipe } from './language/find-language-from-key.pipe';
import { TranslateDirective } from './language/translate.directive';
import { TranslateListDirective } from './language/translate.list.directive';
import { AlertComponent } from './alert/alert.component';
import { AlertErrorComponent } from './alert/alert-error.component';
import { HasAnyAuthorityDirective } from './auth/has-any-authority.directive';
import { FormatNumberPipe } from './pipes/format-number.pipe';
import { FormatHtmlTextPipe } from './pipes/format-html-text.pipe';
import { TranslateNamePipe } from './pipes/translate-name.pipe'
import { TranslateCommentPipe } from './pipes/translate-comment.pipe'
import { TranslateSpecificationPipe } from './pipes/translate-specification.pipe'
import { DurationPipe } from './date/duration.pipe';
import { FormatMediumDatetimePipe } from './date/format-medium-datetime.pipe';
import { FormatMediumDatePipe } from './date/format-medium-date.pipe';
import { FormatMediumTimestampPipe } from './date/format-medium-timestamp.pipe';
import { FormatMediumYearMonthPipe } from './date/format-medium-year-month.pipe';
import { SortByDirective } from './sort/sort-by.directive';
import { SortDirective } from './sort/sort.directive';
import { ItemCountComponent } from './pagination/item-count.component';
import { DecimalPipe } from '@angular/common'

import { EditorModule } from 'primeng/editor';
import { PickListModule } from 'primeng/picklist';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TabViewModule } from 'primeng/tabview';
import { MultiSelectModule } from 'primeng/multiselect';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { MenubarModule } from 'primeng/menubar';
import { PanelMenuModule } from 'primeng/panelmenu';
import { AccordionModule } from 'primeng/accordion';
import { ToastModule } from 'primeng/toast';
import { MessagesModule } from 'primeng/messages';
import { InputNumberModule } from 'primeng/inputnumber';
import { DropdownModule } from 'primeng/dropdown';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SidebarModule } from 'primeng/sidebar';
import { CheckboxModule } from 'primeng/checkbox';
import { GalleriaModule } from 'primeng/galleria';

import { DetailFooterComponent } from './entity-components/detail-footer.component';
import { DetailViewPaginatorComponent } from './entity-components/detail-view-paginator.component';
import { UpdateFooterComponent } from './entity-components/update-footer.component';
import { UpdateHeaderComponent } from './entity-components/update-header.component';
import { UpdateValidationMessagesComponent } from './entity-components/update-validation-messages.component';
import { NameTranslationsUpdateComponent } from './entity-components/name-translations-update.component';

import { DateMaskDirective } from 'app/shared/date/date-mask.directive';
import { DateRangeCalendarDirective } from './date/date-range-calendar.directive';
import { DigitOnlyDirective } from 'app/shared/directives/digit-only.directive';
import { UpperCaseDirective } from 'app/shared/directives/upper-case.directive';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask, IConfig } from 'ngx-mask';

import { TranslateService } from '@ngx-translate/core';
import { GalleriaFullComponent } from 'app/layouts/galleria-full/galleria-full.component';
import { CarouselModule } from 'primeng/carousel';
import {PanelModule} from 'primeng/panel';
import { ButtonLoadingComponent } from './entity-components/button-loading.component';
import { ButtonLoadingInFormComponent } from './entity-components/button-loading-in-form.component';

export const options: Partial<IConfig> | (() => Partial<IConfig>) | null = null;

@NgModule({
  imports: [
    SharedLibsModule,
    EditorModule,
    PickListModule,
    TableModule,
    ButtonModule,
    DialogModule,
    ConfirmDialogModule,
    CalendarModule,
    TabViewModule,
    MultiSelectModule,
    TriStateCheckboxModule,
    MenubarModule,
    PanelMenuModule,
    AccordionModule,
    ToastModule,
    MessagesModule,
    InputNumberModule,
    DropdownModule,
    RadioButtonModule,
    SidebarModule,
    CheckboxModule,
    GalleriaModule,
    CarouselModule,
    PanelModule,
    // NgxMaskModule.forRoot(),
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  declarations: [
    FindLanguageFromKeyPipe,
    TranslateDirective,
    TranslateListDirective,
    AlertComponent,
    AlertErrorComponent,
    HasAnyAuthorityDirective,
    DurationPipe,
    FormatNumberPipe,
    FormatHtmlTextPipe,
    TranslateNamePipe,
    TranslateCommentPipe,
    TranslateSpecificationPipe,
    FormatMediumDatetimePipe,
    FormatMediumDatePipe,
    FormatMediumTimestampPipe,
    FormatMediumYearMonthPipe,
    SortByDirective,
    SortDirective,
    ItemCountComponent,
    DetailFooterComponent,
    DetailViewPaginatorComponent,
    UpdateFooterComponent,
    UpdateHeaderComponent,
    UpdateValidationMessagesComponent,
    NameTranslationsUpdateComponent,
    DateMaskDirective,
    DateRangeCalendarDirective,
    DigitOnlyDirective,
    UpperCaseDirective,
    GalleriaFullComponent,
    ButtonLoadingComponent,
    ButtonLoadingInFormComponent
    //   SortByPipe
  ],
  exports: [
    SharedLibsModule,
    FindLanguageFromKeyPipe,
    TranslateDirective,
    TranslateListDirective,
    AlertComponent,
    AlertErrorComponent,
    HasAnyAuthorityDirective,
    DurationPipe,
    FormatNumberPipe,
    FormatHtmlTextPipe,
    TranslateNamePipe,
    TranslateCommentPipe,
    TranslateSpecificationPipe,
    FormatMediumDatetimePipe,
    FormatMediumDatePipe,
    FormatMediumTimestampPipe,
    FormatMediumYearMonthPipe,
    SortByDirective,
    SortDirective,
    ItemCountComponent,
    EditorModule,
    PickListModule,
    TableModule,
    ButtonModule,
    DialogModule,
    CalendarModule,
    ConfirmDialogModule,
    TabViewModule,
    MultiSelectModule,
    TriStateCheckboxModule,
    MenubarModule,
    PanelMenuModule,
    AccordionModule,
    ToastModule,
    MessagesModule,
    InputNumberModule,
    DropdownModule,
    RadioButtonModule,
    SidebarModule,
    CheckboxModule,
    GalleriaModule,
    CarouselModule,
    PanelModule,
    DetailFooterComponent,
    DetailViewPaginatorComponent,
    UpdateFooterComponent,
    UpdateHeaderComponent,
    UpdateValidationMessagesComponent,
    NameTranslationsUpdateComponent,
    DateMaskDirective,
    DateRangeCalendarDirective,
    DigitOnlyDirective,
    UpperCaseDirective,
    // NgxMaskModule,
    NgxMaskDirective,
    NgxMaskPipe,
    GalleriaFullComponent,
    ButtonLoadingComponent,
    ButtonLoadingInFormComponent
    //    SortByPipe
  ],
  providers: [ConfirmationService, TranslateService, FormatNumberPipe, TranslateNamePipe, TranslateCommentPipe, TranslateSpecificationPipe, DecimalPipe, provideNgxMask()],
})
export class SharedModule {}
